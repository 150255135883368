import ReactDOM from 'react-dom';
import { Cookies } from 'react-cookie';
import CookiesDisabled from '../utils/cookiesDisabled';

const _cookies = new Cookies();

export const GetUserToken = () => {
    if (!navigator.cookieEnabled) {
        ReactDOM.render(<CookiesDisabled />, document.getElementById('root'));
        return true;
    } else {
        return Validate_Token(_cookies.get('AccessToken'));
    }
}

export const Validate_Token = (token_value) => {
    if (typeof (token_value) !== 'undefined' && token_value != null) {
        return token_value;
    } else {
        return SetUserToken(sessionStorage.getItem("AccessToken"))
    }
}

export const SetUserToken = (token) => {
    if (typeof (Storage) !== 'undefined') {
        // sessionStorage.setItem('AccessToken', token);
        return token;
    } else {
        return "";
    }
}

export const IsLogin = (data) => {
    if (!navigator.cookieEnabled) {
        ReactDOM.render(<CookiesDisabled />, document.getElementById('root'));
        return true;
    } else {
        if (data === null) {
            return true;
        }

        if (_cookies.get('AccessToken')) {
            return true;
        } else {
            return false;
        }
    }
}

export const ClearCookies = () => {
    const cookies = new Cookies();
    cookies.remove('loginForm');
    localStorage.clear();
    sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = '/';
}

export const ClearSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = '/';
}

export const IntialCleanUp = () => {
    const cookies = new Cookies();
    cookies.remove('loginForm');
    localStorage.clear();
    sessionStorage.clear();
    window.localStorage.clear();
}