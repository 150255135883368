import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import star from "../assets/images/star.gif";
import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "../components/common/loadingSpinner";

function MakingBreadCard() {
    const { t } = useTranslation();
    const didMount = useRef(false);
    const location = useLocation();
    const [isLoad, setIsLoad] = useState(false);
    const [balance, SetBalance] = useState(0);

    useEffect(() => {
        if (!didMount.current) {
            getBalance();
        }
        return () => {
            didMount.current = true;
        }
    })

    const getBalance = () => {
        if (sessionStorage.UserId != undefined) {
            const body = {
                UserId: Number(sessionStorage.UserId)
            }
            httpService.Get(Employee_Api_EndPoints.GET_BALANCE, body)
                .then((resp) => {
                    if (resp.status == 200) {
                        SetBalance(resp.data.cardBalance);
                    }
                    setIsLoad(true);
                });
        } else {
            setIsLoad(true);
            userService.ClearSession();
        }
    }

    return (
        <Fragment>
            {isLoad && <Fragment>
                {/* <!-- dashboard main start --> */}
                <div class="dashboard-container">
                    <h1 class="available-dough-bigger">{t('CongoYourMakingBrd')}</h1>
                    <div class="congrats-image-container">
                        <img src={star} alt="" />
                    </div>
                    {location?.state?.typeOfTransfer == "loadFundsToCard" ? (
                        <h2 class="page-module-heading mb-15">
                        You have loaded <span class="color-white">$ {location?.state?.bakingValues?.transferredAmount.toFixed(2)}</span> on your
                        DoughCard
                        </h2>
                    ) : (
                        <h2 class="page-module-heading mb-15">
                        You have transferred <span class="color-white">$ {location?.state?.bakingValues?.transferredAmount.toFixed(2)}</span> to your
                        Bank Account
                        </h2>
                    )}
                    <h2 class="page-module-heading">
                        {t('NewDhCrdBal')} <span class="color-white">$ {balance.toFixed(2)}</span>
                    </h2>
                </div>
                {/* <!-- dashboard main end --> */}
            </Fragment>
            }
            {!isLoad && <LoadingSpinner />}
        </Fragment>
    )
}
export default MakingBreadCard;