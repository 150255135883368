import React, { useState, useEffect, useRef, Fragment } from "react";
import * as httpService from "../services/httpService";
import * as userService from "../utils/userService";
import { Employee_Api_EndPoints } from "../global/end-points";
// import * as wageService from '../components/common/wage_calculations';
import LoadingSpinner from '../components/common/loadingSpinner';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function EmployeeTimesheet() {
    const { t } = useTranslation();
    const didMount = useRef(false);

    const timesheetInfo = {
        "employeeTimeSheets": [],
        "totalHours": 0,
        "taxdeductibleBalance": 0,
        "transferredAmount": 0,
        "grossPay": 0,
        "netPay": 0
    };
    const [isLoad, setIsLoad] = useState(false);
    const [employeeInfo, setEmployeeTimesheet] = useState(timesheetInfo);
    // const [transferredAmount, setTransferredAmount] = useState(0);
    // const [taxDeduction, setTaxDeduction] = useState({ totalTax: 0 });
    // const { totalTax } = taxDeduction;
    // const [total, setTotal] = useState({ hour: 0, payamt: 0 });
    // const { hour, payamt } = total;

    useEffect(() => {
        if (!didMount.current) { getTimesheets(); }
        return () => {
            didMount.current = true;
        }
    }, [])

    const getTimesheets = () => {
        if (sessionStorage.UserId != undefined) {
            const body = {
                UserId: Number(sessionStorage.UserId),
                LocationId: 1
            };

            httpService.Get(Employee_Api_EndPoints.GENERATE_TIMESHEET, body).then((resp) => {
                if (resp.status == 200) {
                    setEmployeeTimesheet(resp.data);
                    // setTotal({ ...wageService.CalTotalHrsAmt(resp.data.employeeTimeSheets) })
                    // setTaxDeduction({ ...wageService.CalTaxDedution(resp.data.taxDeduction) });
                    // setTransferredAmount(resp.data.transferredAmount);
                }
                setIsLoad(true);
            })
        } else {
            setIsLoad(true);
            userService.ClearCookies();
        }
    }

    return (
        <Fragment>{isLoad && <Fragment>
            <div className="dashboard-container">
            <Link to="/doughsummary" className="back-link">
              <i class="arrow left"></i> {t("backbutton")}
            </Link>
                <div class="table-container">
                    <h2 class="page-module-heading mb-15">{t('TimeSht')}</h2>
                    <table class="pay-info-table">
                        <thead>
                            <tr>
                                <th>{t('PayPer')}</th>
                                <th>{t('Type')}</th>
                                <th>{t('Hours')}</th>
                                <th>{t('Pay')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeInfo.employeeTimeSheets && employeeInfo.employeeTimeSheets.map((sheet, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{sheet.payPeriod}</td>
                                        <td>{sheet.type}</td>
                                        <td>{sheet.hours}</td>
                                        <td>{sheet.pay.toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td>{employeeInfo.totalHours}</td>
                                <td>{employeeInfo.grossPay.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-container mt-40">
                    <table class="tax-info-table">
                        <tbody>

                            <tr>
                                <td>{t('EstimatedDeduct')}</td>
                                <td></td>
                                <td></td>
                                <td>
                                    $ {employeeInfo.taxdeductibleBalance.toFixed(2)}
                                    {/* $ {Number(Number((totalTax / 100) * payamt).toFixed(2)).toFixed(2)} */}
                                </td>
                            </tr>
                            <tr>
                                <td>{t('TranPaPer')}</td>
                                <td></td>
                                <td></td>
                                <td>$ {employeeInfo.transferredAmount.toFixed(2)}</td>
                                {/* <td>$ {transferredAmount}</td> */}
                            </tr>
                            <tr>
                                <td>{t('NetAvai')} </td>
                                <td></td>
                                <td></td>
                                <td>$ {employeeInfo.netPay.toFixed(2)}</td>
                                {/* <td>$ {Number(payamt - transferredAmount).toFixed(2)}</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>}
            {!isLoad && <LoadingSpinner />}
        </Fragment >
    );

}
export default EmployeeTimesheet;