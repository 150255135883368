import React, { Fragment,useEffect,useState } from "react";
import Cookies from "universal-cookie";
import logo from "../assets/images/logo.svg";

function HomePage() {



    const [isload, setLoad] = useState(false);
  useEffect(() => {
    // if (!didMount.current) {
    const cookies = new Cookies();
    const _loginForm = cookies.get("loginForm");
    if (_loginForm !== undefined) {
      // Object.keys(_loginForm).forEach(cookieName => {
      //   inputValue[cookieName] = _loginForm[cookieName];
      // });

      
    }
    // setInputValue({ ...inputValue });
    setLoad(true);
    // }
    // return () => {
    //   didMount.current = true;
    // };
  }, []);

       

  return (
    <Fragment>
      {" "}
      {isload && (
        <>
          <div className="login-page-header">
            <a href="/" className="logo-container">
              <img src={logo} alt="InstaDouch Logo" className="logo" />
            </a>
            <nav>
                <a href="/homepage" className="nav-menu-item login-link">
                Home
              </a>
              <a href="/company" className="nav-menu-item">
                Company
              </a>
              <a href="/employers" className="nav-menu-item">
                Employers
              </a>
              <a href="/employees" className="nav-menu-item">
                Employees
              </a>
              <a href="/fAQs" className="nav-menu-item">
                FAQs
              </a>
              <a href="/" className="nav-menu-item">
                Login
                <div className="login-dropdown-container">
                  <a href="#" className="login-dropdown-link-item">
                    Employee Login
                  </a>
                  <a href="#" className="login-dropdown-link-item">
                    Employer Login
                  </a>
                </div>
              </a>
            </nav>
          </div>
          {/* <div className="container1"> */}
          {/* <div className="insta-dough-desc-container">
              <h3>Instantly Access your Dough</h3>
              <ul>
                <li>Fast</li>
                <li>Secure</li>
                <li>Easy</li>
              </ul>
              <p>
                Get your earned wages today. Spend on your card, withdraw at an
                ATM, or transfer to your bank!
              </p>
              <p>It’s your money, get it when you need it!</p>
            </div> */}
            {/* </div> */}
            {/* Login screen end  */}
            <div className="homepagenew-container">
            <div className="container1">
          <div className="insta-dough-desc-container">
              <h3>Instantly Access your Dough</h3>
              <ul>
                <li>Fast</li>
                <li>Secure</li>
                <li>Easy</li>
              </ul>
              <p>
                Get your earned wages today. Spend on your card, withdraw at an
                ATM, or transfer to your bank!
              </p>
              <p>It’s your money, get it when you need it!</p>
            </div>
            </div>
            <div className="homepagenew" id="access">
              <h3>Use InstaDough’s DoughPay product to get your earned money today!  We know that you have bills today, why should you wait until payday to access your money… DoughPay is your solution. <br /><br />

It’s easy, register your account, instantly see your eligible wages to access, and start accessing your money! Let’s get doughy…..
</h3>
            </div>
           
            <div className="homepagenew" id="Less">
              <h3>Employer and want to sign-up to help free your employees from financial strain?</h3>
            </div>
            <div className="homepagenew" id="Solution">
              <h3>Employee ready to activate your account?</h3>
            </div>
            <div className="homepagenew" id="Costs">
              <h3>Want to refer your employer and earn $50 referral fee?</h3>
            </div>
         
          </div>

        </>
      )}
    </Fragment>
  );
}

export default HomePage;
