import React, { useState, useRef, useEffect, Fragment } from "react";
import * as httpService from "../services/httpService";
import * as userService from "../utils/userService";
import { Employee_Api_EndPoints } from "../global/end-points";
import LoadingSpinner from "../components/common/loadingSpinner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PayDataTipsheetDownload() {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(false);
  const didMount = useRef(false);
  const years = Array.from(
    { length: 2 },
    (_, i) => new Date().getFullYear() - i
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [yearlyTipsheet, setYearlyTipsheet] = useState([
    { month: "", payPeriod: "", amount: 0 },
  ]);

  useEffect(() => {
    if (!didMount.current) {
      getTipsheetReport(selectedYear);
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getTipsheetReport = (selectedYear) => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        Year: selectedYear,
      };
      httpService
        .Get(Employee_Api_EndPoints.PAYDATA_TIPSHEET, body)
        .then((resp) => {
          if (resp.status == 200) {
            setYearlyTipsheet([...resp.data]);
          }
          setIsLoad(true);
        });
    } else {
      setIsLoad(true);
      userService.ClearCookies();
    }
  };

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          <div class="dashboard-container">
            <Link to="/paysheet" className="back-link">
              <i class="arrow left"></i>{t("backbutton")}
            </Link>

            <h2 class="page-module-heading mb-15">{t("Tips")}</h2>
            <div class="year-selection-container mb-10 mt-20">
             {t("SelectYear")}
              <select
                class="timeselect-dropdown"
                value={selectedYear}
                onChange={(e) =>
                  setSelectedYear(
                    parseInt(e.target.value),
                    getTipsheetReport(parseInt(e.target.value))
                  )
                }
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div class="table-container">
              <table class="pay-info-table">
                <thead>
                  <tr>
                    <th>{t("Month")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amnt")}</th>
                    <th>{t("down/open")}</th>
                  </tr>
                </thead>
                <tbody>
                  {yearlyTipsheet &&
                    yearlyTipsheet.map((sheet, index) => {
                      return (
                        <tr key={index}>
                          <td class="text-center">{sheet.month}</td>
                          <td class="text-center">{sheet.payPeriod}</td>
                          <td class="text-right">{sheet.amount.toFixed(2)}</td>
                          <td class="text-center">{t("Download")}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      )}
      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default PayDataTipsheetDownload;
