import React, { Fragment, useState, useEffect } from "react";
import InpuField from "../components/common/input";
import Button from "../components/common/button";
// import LoginImage from "../assets/images/login_01.svg";
// import cookVideo from "../assets/video/cook.mp4";
import User from "../assets/images/user.svg";
import Lock from "../assets/images/lock.svg";
import viewPassword from "../assets/images/show.png";
import hidePassword from "../assets/images/hide.png";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import ErrorMessage from "../components/common/error_message";
import { Employee_Api_EndPoints } from "../global/end-points";
// import * as bindToken from '../components/token';
import * as httpService from "../services/httpService";
import Cookies from "universal-cookie";
import * as userService from "../utils/userService";
import logo from "../assets/images/logo.svg";

function Login() {
  const initialState = {
    employeeid: "",
    password: "",
    rememberMe: false,
    UserName: "",
    AccessToken: "",
    UserId: 0,
  };

  let navigate = useNavigate();
  // const didMount = useRef(false);
  const [isload, setLoad] = useState(false);
  const [inputValue, setInputValue] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ inputFields: "" });
  const { inputFields } = errorMessage;

  useEffect(() => {
    // if (!didMount.current) {
    const cookies = new Cookies();
    const _loginForm = cookies.get("loginForm");
    if (_loginForm !== undefined) {
      // Object.keys(_loginForm).forEach(cookieName => {
      //   inputValue[cookieName] = _loginForm[cookieName];
      // });

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        ..._loginForm,
      }));
    }
    // setInputValue({ ...inputValue });
    setLoad(true);
    // }
    // return () => {
    //   didMount.current = true;
    // };
  }, []);

  // const handleChange = (key, value) => {
  //   setErrorMessage("");
  //   inputValue[key] = value;
  //   setInputValue((prev) => ({
  //     ...prev,
  //     ...inputValue
  //   }));
  // };

  const handleChange = (key, value) => {
    setErrorMessage("");
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };

  // const validate = (value) => {
  //   if (
  //     validator.isStrongPassword(value, {
  //       minLength: 8,
  //       minLowercase: 1,
  //       minUppercase: 1,
  //       minNumbers: 1,
  //       minSymbols: 1,
  //     })
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const validate = (value) => {
    return validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
  };

  const field_validation = () => {
    let isValid = true;
    if (
      inputValue.employeeid.trim() === "" &&
      inputValue.password.trim() === ""
    ) {
      setErrorMessage({ inputFields: "Invalid Login" });
      isValid = false;
    }
    if (
      inputValue.employeeid.trim() === "" &&
      inputValue.password.trim() !== ""
    ) {
      setErrorMessage({ inputFields: "Please enter the employeeId" });
      isValid = false;
    }

    if (
      inputValue.employeeid.trim() !== "" &&
      inputValue.password.trim() === ""
    ) {
      setErrorMessage({ inputFields: "Please enter the password" });
      isValid = false;
    }
    return isValid;
  };

  const handleClick = () => {
    if (field_validation()) {
      if (validate(inputValue.password)) {
        const tokenKeys = {
          UserName: inputValue.employeeid,
          Password: inputValue.password,
          AccessToken: inputValue.AccessToken,
        };

        httpService.Get(Employee_Api_EndPoints.AUTHORIZATION, tokenKeys).then(
          (resp) => {
            if (
              resp.status == 200 &&
              resp.data != null &&
              resp.data.userID > 0
            ) {
              if (inputValue.rememberMe) {
                const cookies = new Cookies();
                inputValue.UserId = resp.data?.userID;
                inputValue.UserName = resp.data?.userName;
                inputValue.AccessToken = resp.data?.accessToken;
                cookies.set("loginForm", inputValue);
              } else {
                userService.IntialCleanUp();
              }
              sessionStorage.setItem("AccessToken", resp.data?.accessToken);
              sessionStorage.setItem("UserName", resp.data?.userName);
              sessionStorage.setItem("UserId", resp.data?.userID);

              navigate("/home");
            } else {
              setErrorMessage({ inputFields: "Invalid Login" });
              inputValue.employeeid = "";
              inputValue.password = "";
              setInputValue({ ...inputValue });
              userService.IntialCleanUp();
            }
            // setIsFlag(false);
          },
          (err) => {
            // setIsFlag(false);
            console.log(err);
            userService.ClearCookies();
          }
        );
      } else {
        // setErrorMessage({ inputFields: "Password Invalid Formate" });
        setErrorMessage({ inputFields: "Invalid Login" });
      }
    }
  };

  const Click_Signup = () => {
    navigate("/signup");
  };

  const handleChecked = (event) => {
    inputValue.rememberMe = event.target.checked;
    setInputValue({ ...inputValue });
  };
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      handleClick();
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      {" "}
      {isload && (
        <>
          <div className="login-page-header">
            <a href="/" className="logo-container">
              <img src={logo} alt="InstaDouch Logo" className="logo" />
            </a>
            <nav>
            <a href="/homepage" className="nav-menu-item">
                Home
              </a>
              <a href="/company" className="nav-menu-item">
                Company
              </a>
              <a href="/employers" className="nav-menu-item">
                Employers
              </a>
              <a href="/employees" className="nav-menu-item">
                Employees
              </a>
              <a href="/fAQs" className="nav-menu-item">
                FAQs
              </a>
              <a href="/" className="nav-menu-item login-link">
                Login
                <div className="login-dropdown-container">
                  <a href="#" className="login-dropdown-link-item">
                    Employee Login
                  </a>
                  <a href="#" className="login-dropdown-link-item">
                    Employer Login
                  </a>
                </div>
              </a>
            </nav>
          </div>
          <div className="login-register">
            {/* <div className="insta-dough-desc-container">
              <h3>Instantly Access your Dough</h3>
              <ul>
                <li>Fast</li>
                <li>Secure</li>
                <li>Easy</li>
              </ul>
              <p>
                Get your earned wages today. Spend on your card, withdraw at an
                ATM, or transfer to your bank!
              </p>
              <p>It’s your money, get it when you need it!</p>
            </div> */}

            <div className="login-image">
              {/* <img src={LoginImage} alt="" /> */}
              {/* <div className="login-video-background-layer"></div> */}
              <div className="login-video-bg-merge-top"></div>
              <div className="login-video-bg-merge-bottom"></div>
              <div className="login-video-bg-merge-left"></div>
              <div className="login-video-bg-merge-right"></div>
              {/* <video
                controls={false}
                className="login-video-frame"
                muted={true}
                autoPlay={true}
                loop={true}
                playsInline={true}
              >
                <source src={cookVideo} type="video/mp4" />
              </video> */}
            </div>

            {/* Login form start  */}
            <div className="login-form">
              {/* <div className="logo">
              <img src={logo} alt="InstaDouch Logo" />
            </div> */}

              <h2 class="login-register-form-heading mt-40 mb-20">
                Sign <span>In</span>
              </h2>
              <div onKeyDown={handleEnter}>
                <div className="form-row">
                  <InpuField
                    class="input-field"
                    type="text"
                    name="employeeid"
                    value={inputValue.employeeid}
                    placeholder="Enter Email Id"
                    icon={User}
                    iconClass={`user-icon`}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <InpuField
                    class="input-field"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={inputValue.password}
                    placeholder="Enter Password"
                    icon={Lock}
                    iconClass={`lock-icon`}
                    onChange={handleChange}
                  />
                  <button
                    onClick={handleTogglePassword}
                    className="showHidePassword"
                  >
                    {showPassword ? (
                      <img
                        className="logo"
                        src={viewPassword}
                        alt="InstaDouch Logo"
                      />
                    ) : (
                      <img
                        className="logo"
                        src={hidePassword}
                        alt="InstaDouch Logo"
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="form-row">
                {inputFields === "" ? null : (
                  <ErrorMessage message={inputFields} />
                )}
              </div>
              <div className="form-row show-password">
                <input
                  type="checkbox"
                  checked={inputValue.rememberMe}
                  onChange={handleChecked}
                />
                Remember me
              </div>

              <div className="form-row forgot-password">
                <a href="">Forgot Password?</a>
              </div>

              <div className="form-row">
                <Button
                  label="Sign In"
                  className="btn btn-login"
                  onClick={handleClick}
                />
              </div>

              <div className="form-row new-user-link-wrap">
                <a
                  className="new-user-link"
                  style={{ cursor: "pointer" }}
                  onClick={Click_Signup}
                >
                  Create new Account
                </a>
              </div>
            </div>
            {/* Login screen end  */}
          </div>
        </>
      )}
    </Fragment>
  );
}

export default Login;
