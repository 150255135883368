import React, { Fragment, useEffect, useRef, useState } from "react";
import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import LoadingSpinner from "../components/common/loadingSpinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExceptionPopup from '../components/common/exceptionhandle';

function DoughACHTransfers() {
  const { t } = useTranslation();
  const didMount = useRef(false);
  const [isLoad, setIsLoad] = useState(false);
  const [balance, SetBalance] = useState(0);
  const [cards, setCards] = useState([]);
  const [selectCard, setSelectedCard] = useState('');
  const [transferbal, setTransferbal] = useState('');
  const [achFee, SetAchFee] = useState(5);
  let navigate = useNavigate();
  const [isException, setIsException] = useState(false);
  const [exceptionMsg, setExceptionMsg] = useState({ title: "warning", content: "This is a sample content" });

  useEffect(() => {
    if (!didMount.current) {
      getBalance();
      getCards();
    }
    return () => {
      didMount.current = true;
    };
  });

  const getBalance = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService.Get(Employee_Api_EndPoints.GET_BALANCE, body).then((resp) => {
        if (resp.status == 200) {
          SetBalance(resp.data.cardBalance);
        }
        setIsLoad(true);
      });
    } else {
      setIsLoad(true);
      userService.ClearSession();
    }
  };

  const getCards = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };

      httpService
        .Get(Employee_Api_EndPoints.GET_USER_CARDS, body)
        .then((resp) => {
          if (resp.status == 200) {
            setCards(resp.data);

            httpService.Get(Employee_Api_EndPoints.GET_ACH_FEE)
              .then((resp) => {
                if (resp.status == 200) {
                  SetAchFee(resp.data?.achFee);
                }
              });
          }
        });
    } else {
      userService.ClearSession();
    }
  };

  const validate = () => {
    if (transferbal !== "" && selectCard !== "") {
      return true;
    }
    return false;
  };

  const TransferAmount = () => {
    if (validate()) {
      if (sessionStorage.UserId != undefined) {
        var body = {
          UserId: Number(sessionStorage.UserId),
          CardId: selectCard,
          TransferAmount: Number(transferbal),
          TransferFee: achFee,
          TransferType: "Account"
        }

        httpService
          .Get(Employee_Api_EndPoints.CARD_TRANSACTION, body)
          .then((resp) => {

            if (resp.data.errorFound === true && resp.data.errorCode === "NO_CARD_DETAILS") {
              setExceptionMsg({ title: "Warning", content: t("Transactionfailedtoast") });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === "EXCEPTION_ERROR") {
              setExceptionMsg({ title: "Error", content: resp.data.errorMessage + <br/> + " Please contact with administrative team. Thank you!" });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === "CARD_NOT_LINKED") {
              setExceptionMsg({ title: "Warning", content: "Card account details not found" });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === "ACH_RESPONSE") {
              setExceptionMsg({ title: "Warning", content: resp.data.errorMessage + <br/> + " Please contact with administrative team. Thank you!" });
              setIsException(true);
            } else {
              if (resp.data.errorFound === false && resp.status == 200) {
                const body = { transferredAmount: transferbal };
                navigate("/makingbreadcard", { 
                  state: {
                    bakingValues: body,
                    typeOfTransfer: 'bankAchTransfer'
                  }
                });

                toast.success(t("CardtrsToasterr3"), {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                });
              }

            }
          });
      }
      else {
        userService.ClearSession();
      }
    }
  };

  const onBalanceChange = (event) => {
    event.preventDefault();
    const enterValue = event.target.value.replace(/[^0-9]/g, '');
    if (Number(enterValue) <= (Number(balance) - Number(achFee))) {
      setTransferbal(Number(enterValue));
    }
  };

  const handleClosePopup = () => {
    setIsException(false);
  };

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          <div class="dashboard-container">
            <Link to="/doughcard" className="back-link">
              <i class="arrow left"></i> {t("backbutton")}
            </Link>
            <h2 class="page-module-heading mb-15">{t("ACHTran")}</h2>
            <h2 class="page-module-heading">{t("AvaiTranBal")}</h2>
            <h1 class="available-dough-bigger">$ {balance.toFixed(2)}</h1>
            <div class="ach-transfers">
              <div class="wage-input-container">
                <input
                  type="text"
                  class="input-amount"
                  placeholder={t("amounttotransferPlaceholder")}
                  value={transferbal}
                  onChange={onBalanceChange}
                />
                <div class="bank-accounts-dropdown-container">
                  <select
                    class="dropdown-list mr-10"
                    value={selectCard}
                    onChange={(e) => setSelectedCard(e.target.value)}
                  >
                    <option value="">{t("SelectAcc")}</option>
                    {cards.map((card) => {
                      return (
                        <option key={card.id} value={card.id}>
                          {card.accountDisplayName}
                        </option>
                      );
                    })}
                  </select>
                  <Link to="/addaccount" className="manage-link">
                    {t("MN")}
                  </Link>
                </div>
                <h2 class="page-module-heading">
                  {t("ACHFee")} <span class="color-white">$ {achFee}.00</span>
                </h2>
                <button
                  class="btn mx-auto mt-50 display-flex w-100-percent"
                  onClick={TransferAmount}
                >
                  {t("TranMon")}
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {!isLoad && <LoadingSpinner />}
      {isException && <ExceptionPopup title={exceptionMsg.title} content={exceptionMsg.content} onClose={handleClosePopup} />}
    </Fragment>
  );
}
export default DoughACHTransfers;
