import React, { Fragment,useEffect,useState } from "react";
import Cookies from "universal-cookie";
import logo from "../assets/images/logo.svg";

function Employers() {



    const [isload, setLoad] = useState(false);
  useEffect(() => {
    // if (!didMount.current) {
    const cookies = new Cookies();
    const _loginForm = cookies.get("loginForm");
    if (_loginForm !== undefined) {
      // Object.keys(_loginForm).forEach(cookieName => {
      //   inputValue[cookieName] = _loginForm[cookieName];
      // });

      
    }
    // setInputValue({ ...inputValue });
    setLoad(true);
    // }
    // return () => {
    //   didMount.current = true;
    // };
  }, []);

       

  return (
    <Fragment>
      {" "}
      {isload && (
        <>
          <div className="login-page-header">
            <a href="/" className="logo-container">
              <img src={logo} alt="InstaDouch Logo" className="logo" />
            </a>
            <nav>
            <a href="/homepage" className="nav-menu-item">
                Home
              </a>
              <a href="/company" className="nav-menu-item">
                Company
              </a>
              <a href="/employers" className="nav-menu-item login-link">
                Employers
              </a>
              <a href="/employees" className="nav-menu-item">
                Employees
              </a>
              <a href="/fAQs" className="nav-menu-item">
                FAQs
              </a>
              <a href="/" className="nav-menu-item">
                Login
                <div className="login-dropdown-container">
                  <a href="#" className="login-dropdown-link-item">
                    Employee Login
                  </a>
                  <a href="#" className="login-dropdown-link-item">
                    Employer Login
                  </a>
                </div>
              </a>
            </nav>
          </div>
            {/* Login screen end  */}
            <div className="employers-container-right">
          <div className="employers" >
          <h3>Who would have known, doing the right thing, is the profitable thing!</h3>
          </div>
          </div>
            <div className="employers-container">
            <div className="employers" id="DoughPay">
              <h3>Offering DoughPay, an instant pay, earned wage access product to your employees offers significant benefits to your organization including:</h3>
            </div>
            <div className="employers" id="cost">
              <h3>No Cost to Implement</h3>
            </div>
            <div className="employers" id="Expenses">
              <h3>Reduce Expenses</h3>
            </div>
            <div className="employers" id="productivity">
              <h3>Improve Productivity</h3>
            </div>
            <div className="employers" id="retention">
              <h3>Employee Retention and Attraction</h3>
            </div>
            <div className="employers" id="capital">
              <h3>Improving Net Working Capital Float</h3>
            </div>
          </div>
          <div className="contact-us-widget">
              <h3>Contact Us</h3>
            </div>
        </>
      )}
    </Fragment>
  );
}

export default Employers;
