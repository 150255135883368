import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
//import { useNavigate } from "react-router-dom";
//import Button from "../components/common/button";
//import InpuField from "../components/common/input";

function HelpPage() {
    const { t } = useTranslation();
    const supportEmail = 'operations@instadough.io';
    //const navigate = useNavigate();
    //const [query, setQuery] = useState("");
    // const [formData, setFormData] = useState({
    //     name: "",
    //     email: "",
    //     query: "",
    // });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     // You can implement the logic to send the query to the support email or perform any other action here
    //     // For example, you can use a service or API to handle the form submission
    //     console.log("Query submitted:", formData);
    //     // Clear the form after submission
    //     setFormData({
    //         name: "",
    //         email: "",
    //         query: "",
    //     });
    // };

    return (
        <Fragment>
            <div className="dashboard-container">
            <h2 className="helphead">{t("Help!")}</h2>
            <div className="helpdata"><h2 class="page-module-heading">
              {t("HelpContent")}
            </h2>
            </div>
            <div>
        <h3 className="contactdata">{t("Contact Information:")}</h3>
        <p className="contactdata">Email: <a href={`mailto: ${supportEmail}`}>{supportEmail}</a></p>
      </div>
            {/* <div className="form-row">
                    <InpuField
                      className="input-field"
                      type="text"
                      name="name"
                      value={formData.name}
                      placeholder={t("name")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <InpuField
                      className="input-field"
                      type="text"
                      name="email"
                      value={formData.email}
                      placeholder={t("email")}
                      onChange={handleChange}
                    />
                  </div>
                    <div class="paydata-home mt-30">
                    <Button 
                    className="btn btn-paydata w-100-percent"
                    label={t("Submit")}
                    value="Query"
                    onClick={handleSubmit}/>
                    </div> */}
                </div>
        </Fragment>
    );
}

export default HelpPage;
