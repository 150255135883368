import React, { Fragment } from "react";
import logo from "../../assets/images/logo.svg";
import home from "../../assets/images/dashboard-home.svg";
import wallet from "../../assets/images/wallet.svg";
import transactions from "../../assets/images/transactions.svg";
import card from "../../assets/images/card.svg";
import dollar from "../../assets/images/dollar.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as httpService from "../../services/httpService";
import { Employee_Api_EndPoints } from "../../global/end-points";

function MenuComponent() {
  const { t } = useTranslation();

  const getVirtualToken = (e) => {
    e.preventDefault();
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService.Get(Employee_Api_EndPoints.GET_VIRTUAL_CARD_TOKEN, body)
        .then((resp) => {
          window.open(resp.data.url);
        }, () => {
          console.log('error occured !');
        })
    }
  }

  return (
    <Fragment>
      {/* <div className="dashboard"> */}
      <div className="dashboard-left">
        <img src={logo} alt="InstaDouch Logo" className="logo-dashboard" />

        <div className="menu-wrapper">
          {/* menu item */}
          <NavLink className={(navData) => "menu-item " + (navData.isActive ? "active" : "none")} to="/home">
            <span>
              <img src={home} alt="" className="menu-icon" />
            </span>
            {t("Home")}
          </NavLink>

          {/* menu item */}
          <NavLink
            className={(navData) => "menu-item " + (navData.isActive ? "active" : "none")} to="/transactions" >
            <span>
              <img src={transactions} alt="" className="menu-icon" />
            </span>
            {t("RecentTrns")}
          </NavLink>

          {/* menu item */}
          <NavLink className={(navData) => "menu-item " + (navData.isActive ? "active" : "none")} to="/doughcard">
            <span>
              <img src={card} alt="" className="menu-icon" />
            </span>
            {t("DhCrd")}
          </NavLink>

          {/* menu item */}
          <NavLink className={(navData) => "menu-item " + (navData.isActive ? "active" : "none")} to="/paysheet" >
            <span>
              <img src={dollar} alt="" className="menu-icon" />
            </span>
            {t("PayData")}
          </NavLink>

          {/* menu item */}
          {/* <NavLink className={(navData) => "menu-item " + (navData.isActive ? "active" : "none")} to="/virtualcard">
            <span>
              <img src={card} alt="" className="menu-icon" />
            </span>
            {t("VirtualCard")}
          </NavLink> */}

          <a className="menu-item" onClick={getVirtualToken}>
            <span>
              <img src={card} alt="" className="menu-icon" />
            </span>
            {t("VirtualCard")}
          </a>

          {/* menu item */}
          <NavLink className={(navData) => "menu-item " + (navData.isActive ? "active" : "none")} to="/helppage" >
            <span>
              <img src={wallet} alt="" className="menu-icon" />
            </span>
            {t("Help")}
          </NavLink>
          {/* <a className="menu-item">
            <span>
              <img src={wallet} alt="" className="menu-icon" />
            </span>
            {t("Help")}
          </a> */}
        </div>
      </div>
      {/* </div> */}
    </Fragment>
  );
}
export default MenuComponent;
