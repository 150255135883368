import React, { Fragment } from "react";

function LoadingSpinner() {

    return (
        <Fragment>
            <div className="dashboard-container">
                <div className="loading-spinner">
                </div>
            </div>
        </Fragment>
    )
}
export default LoadingSpinner;