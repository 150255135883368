import React, { Fragment, useEffect, useRef, useState } from "react";
import Button from "../components/common/button";
import { useNavigate } from "react-router-dom";
import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import LoadingSpinner from "../components/common/loadingSpinner";
import { useTranslation } from "react-i18next";

function DoughCard() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const didMount = useRef(false);
  const [balance, SetBalance] = useState(0);
  const [ cardLast4Digits, setCardLast4Digits ] = useState("");
  useEffect(() => {
    if (!didMount.current) {
      getBalance();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getBalance = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService.Get(Employee_Api_EndPoints.GET_BALANCE, body).then((resp) => {
        if (resp.status == 200 && !resp.data.errorFound) {
          SetBalance(resp.data.cardBalance);
          setCardLast4Digits(resp.data.cardLast4Digits);
        }
        else
        {
          // Assigning 0.00 for Card balance and 1234 for Last4CardDigits if card details are not found 
          SetBalance(0.00);
          setCardLast4Digits(1234);
        }
        setIsLoad(true);
      });
    } else {
      setIsLoad(true);
      userService.ClearSession();
    }
  };

  const toNavigate = (event) => {
    event.preventDefault();
    switch (event.target.value) {
      case "tranctions":
        navigate("/servicetransaction");
        break;
      case "doughdepotransaction":
          navigate("/depotransaction");
          break;
      case "dchtobank":
        navigate("/achtransaction");
        break;
      case "otherActions":
        navigate("/othertransaction");
        break;
    }
  };

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          {/* <!-- dashboard main start --> */}
          <div class="dashboard-container">
            <h2 class="page-module-heading mb-15">{t("YourDoughCard")}</h2>
            <div class="your-dough-card-continer">
              {/* <!-- card inner start --> */}
              <div class="summary-card-inner">
                {/* <!-- card details start --> */}
                <div class="summary-card-details">
                  <h2 class="card-heading">{t("doughcardsummary")}</h2>
                  <h2 class="wages-heading">{t("doughcardWagestoAccess")}</h2>
                  <h2 class="wage-amount-no-link">
                    $ {balance.toFixed(2)}
                  </h2>
                  <h3 class="wage-card-number">**** **** **** {cardLast4Digits}</h3>
                  <div class="card-holder-details-provider">
                    <div class="card-holder-details">
                      <div class="valid">
                        Valid Thru
                        <span>08/28</span>
                      </div>
                      <div class="valid">
                        Card holder
                        <span>{sessionStorage?.UserName}</span>
                      </div>
                    </div>
                    <div class="card-provider-symbol">
                      <img src="./images/card-provider-logo.svg" alt="" />
                    </div>
                  </div>
                </div>
                {/* <!-- card details end --> */}
              </div>
              {/* <!-- card inner end --> */}
            </div>
            <h2 class="page-module-heading">
              {t("Youhave")}{" "}
              <span class="color-white">$ {balance.toFixed(2)}</span> {t("yourDoughCard")}
            </h2>
            <div class="paydata-home mt-30">
              <Button
                className="btn btn-paydata w-100-percent"
                label={t("RecentDoughCardTrs")}
                value="tranctions"
                onClick={toNavigate}
              />
               <Button
                className="btn btn-paydata w-100-percent"
                label={t("RecentDoughDepoTrs")}
                value="doughdepotransaction"
                onClick={toNavigate}
              />
              <Button
                className="btn btn-paydata w-100-percent"
                label={t("DhAchBank")}
                value="dchtobank"
                onClick={toNavigate}
              />
              <Button
                className="btn btn-paydata w-100-percent"
                label={t("OtherAct")}
                value="otherActions"
                onClick={toNavigate}
              />
            </div>
          </div>
          {/* <!-- dashboard main end --> */}
        </Fragment>
      )}
      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default DoughCard;
