import React, { Fragment, useState, useRef, useEffect } from "react";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import { useTranslation } from "react-i18next";

function InstaTransactions() {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(false);
  const didMount = useRef(false);
  const [recentTransaction, setRecentTransaction] = useState([]);

  useEffect(() => {
    if (!didMount.current) {
      getTransactionInfo();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getTransactionInfo = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService
        .Get(Employee_Api_EndPoints.GET_TRANSACTION_DETAILS, body)
        .then((resp) => {
          setIsLoad(true);
          setRecentTransaction(resp.data);
        });
    }
  };

  // recentTransaction = [{ 'Date': '01-05-23', 'Description': 'CVS', 'Type': 'Purchase', 'Amount': '7' },
  // { 'Date': '01-15-23', 'Description': 'Publix', 'Type': 'Purchase', 'Amount': '15' },
  // { 'Date': '01-18-23', 'Description': 'WellsFargo', 'Type': 'ATM', 'Amount': '200' }]

  return (
    <Fragment>
      {isLoad && (
        <div className="dashboard-container">
          <h2 className="text-center color-white">{t("recentTrsTitle")}</h2>
          <div className="table-container">
            {recentTransaction.length > 0 && (
              <>
                <table className="pay-info-table">
                  <thead>
                    <tr>
                      <th>{t("Date")}</th>
                      <th>{t("Description")}</th>
                      <th>{t("TransType")}</th>
                      <th>{t("Amnt")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentTransaction &&
                      recentTransaction.map((transfer, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              {transfer.transferDate}
                            </td>
                            <td className="text-center">
                              {transfer.description}
                            </td>
                            <td className="text-center">{transfer.payType}</td>
                            <td className="text-center">$ {transfer.amount.toFixed(2)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
            {recentTransaction.length === 0 && (
              <div className="nodata">{t("nodatafoundmsg")}</div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
export default InstaTransactions;
