import React, { useState, useRef, useEffect, Fragment } from "react";
import * as httpService from "../services/httpService";
import * as userService from "../utils/userService";
import { Employee_Api_EndPoints } from "../global/end-points";
// import * as wageService from '../components/common/wage_calculations';
import LoadingSpinner from '../components/common/loadingSpinner';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function TIPSheet() {
  const { t } = useTranslation();
  var tipSheetInfo = {
    "earnedTipDetails": [],
    "taxdeductibleBalance": 0,
    "transferredAmount": 0,
    "netPay": 0
  };

  const [isLoad, setIsLoad] = useState(false);
  const [employeeTipInfo, setEmployeeTipDetails] = useState(tipSheetInfo);
  // const [taxDeduction, setTaxDeduction] = useState({ totalTax: 0 });
  // const [transferredAmount, setTransferredAmount] = useState(0);
  // const { totalTax } = taxDeduction;
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      getTipCompnent();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getTipCompnent = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        LocationId: 1,
      };
      httpService
        .Get(Employee_Api_EndPoints.GENERATE_TIP_COMPONENT, body)
        .then((resp) => {
          if (resp.status == 200) {
            setEmployeeTipDetails(resp.data);
            // setTaxDeduction({ ...wageService.CalTaxDedution(resp.data?.taxDeduction) });
            // setTransferredAmount(resp.data.transferredAmount);
          }
          setIsLoad(true);
        });
    } else {
      setIsLoad(true);
      userService.ClearCookies();
    }
  };

  return (
    <Fragment>
      {isLoad &&
        <Fragment>
          <div class="dashboard-container">
            <Link to="/doughsummary" className="back-link">
              <i class="arrow left"></i> {t("backbutton")}
            </Link>
            <div class="table-container">
              <h2 class="page-module-heading mb-15">{t('Tip')}</h2>
              <table class="pay-info-table">
                <thead>
                  <tr>
                    <th>{t('PayPer')}</th>
                    <th>{t('Type')}</th>
                    <th>{t('Amnt')}</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeTipInfo.earnedTipDetails &&
                    employeeTipInfo.earnedTipDetails.map((tip, index) => {
                      return (
                        <tr key={index}>
                          <td>{tip.payPeriod}</td>
                          <td>{tip.payType}</td>
                          <td>$ {tip.pay.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div class="dashboard-container">
            <div class="table-container mt-40">
              <table class="tax-info-table">
                <tbody>
                  <tr>
                    <td>{t('EstimatedDeduct')}</td>
                    <td></td>
                    <td></td>
                    <td>$ {employeeTipInfo.taxdeductibleBalance.toFixed(2)}</td>
                    {/* <td>$ {Number(Number(totalTax / 100).toFixed(2) * employeeTipDetails.map(x => x.pay)).toFixed(2)} </td> */}
                  </tr>
                  <tr>
                    <td>{t('TranPaPer')}</td>
                    <td></td>
                    <td></td>
                    {/* <td>$ {transferredAmount}</td> */}
                    <td>$ {employeeTipInfo.transferredAmount.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>{t('NetAvai')} </td>
                    <td></td>
                    <td></td>
                    <td>$ {employeeTipInfo.netPay.toFixed(2)}</td>
                    {/* <td>$ {Number(employeeTipDetails.map(x => x.pay) - transferredAmount).toFixed(2)}</td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      }
      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default TIPSheet;
