import React, { useState, Fragment, useRef, useEffect } from "react";
import InpuField from "../components/common/input";
import Button from "../components/common/button";
import Name from "../assets/images/name.svg";
import Email from "../assets/images/email.svg";
import Lock from "../assets/images/lock.svg";
import Mobile from "../assets/images/mobile.svg";
// import Input from "react-phone-number-input/input";
import User from "../assets/images/user.svg";
import viewPassword from "../assets/images/show.png";
import hidePassword from "../assets/images/hide.png";
import validator from "validator";
import ErrorMessage from "../components/common/error_message";
import * as httpService from "../services/httpService";
import * as userService from "../utils/userService";
import { Employee_Api_EndPoints } from "../global/end-points";
import LoadingSpinner from '../components/common/loadingSpinner';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function UpdateProfile() {
  // let navigate = useNavigate();
  const { t } = useTranslation();
  const didMount = useRef(false);
  const [isLoad, setIsLoad] = useState(false);
  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    workPlace: "",
    employeeId: "",
    designation: "",
    password: "",
    npassword: "",
    cnpassword: "",
  });
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    workPlace,
    employeeId,
    designation,
    password,
    npassword,
    cnpassword,
  } = inputValue;
  const [errorSet, setErrorSet] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    workPlace: "",
    employeeId: "",
    designation: "",
    password: "",
    npassword: "",
    cnpassword: "",
    allEmpty: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (!didMount.current) {
      getUserInfoByID();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getUserInfoByID = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService
        .Get(Employee_Api_EndPoints.GET_USER_INFO, body)
        .then((resp) => {
          if (resp.status == 200) {
            setIsLoad(true);
            setInputValue({ ...resp.data });
          } else {
            setIsLoad(false);
          }
        });
    } else {
      setIsLoad(false);
      userService.ClearCookies();
    }
  };

  const handleChange = (key, value) => {
    setInputValue((prev) => ({
      ...prev,
      [key]: value,
    }));

    setErrorSet((err) => ({
      ...err,
      [key]: "",
    }));
  };

  const validate_field_errors = () => {
    let err_validate = errorSet;
    Object.keys(inputValue).forEach((key) => {
      switch (key) {
        case "firstName":
          if (!inputValue.firstName.trim()) {
            err_validate.firstName = "*";
          } else {
            if (inputValue.firstName.length < 2) {
              err_validate.firstName = t("Invalidentry");
            }
          }
          break;

        case "lastName":
          if (!inputValue.lastName.trim()) {
            err_validate.lastName = "*";
          } else {
            if (inputValue.lastName.length < 2) {
              err_validate.lastName =  t("Invalidentry");
            }
          }
          break;
        case "email":
          if (!inputValue.email.trim()) {
            err_validate.email = "*";
          } else {
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                inputValue.email
              )
            ) {
              err_validate.email = t("invalidEmail");
            }
          }
          break;
        case "phoneNumber":
          if (!inputValue.phoneNumber.trim()) {
            err_validate.phoneNumber = "*";
          } else {
            const phoneRegex =
              /^\+?1?[-. ]?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
            if (!phoneRegex.test(inputValue.phoneNumber)) {
              err_validate.phoneNumber = t("invalidPhonenumber");
            }
          }
          break;
        case "employeeId":
          if (!inputValue.employeeId.trim()) {
            err_validate.employeeId = "*";
          }
          break;
        case "password":
          if (IsvalidateValue(inputValue.password).length > 0) {
            const { password, npassword, cnpassword } = inputValue;
            if (!validate_strongPassword(password)) {
              err_validate.password = t( "invalidformate");
            }
            else if (IsvalidateValue(npassword).length === 0 && IsvalidateValue(cnpassword).length === 0) {
              err_validate.npassword = "*";
              err_validate.cnpassword = "*";
              err_validate.allEmpty = t("AllFieldsMandatory");
            }
          }
          break;
        case "npassword":
          if (inputValue.npassword.trim().length > 0) {
            if (!validate_strongPassword(inputValue.npassword)) {
              err_validate.npassword = t( "invalidformate");
            } else {
              const { password, npassword, cnpassword } = inputValue;
              if (IsvalidateValue(password).length == 0 && IsvalidateValue(npassword).length > 0) {
                err_validate.password = "*";
                err_validate.allEmpty = t("AllFieldsMandatory");
              }
              else if (IsvalidateValue(password) === IsvalidateValue(npassword)) {
                err_validate.npassword =t("CNareSame");
              } else if (IsvalidateValue(npassword).length > 0 && IsvalidateValue(cnpassword).length == 0) {
                err_validate.cnpassword = "*";
                err_validate.allEmpty = t("AllFieldsMandatory");

              }
            }
          }
          break;
        case "cnpassword":
          if (inputValue.cnpassword.trim().length > 0) {
            if (!validate_strongPassword(inputValue.cnpassword)) {
              err_validate.cnpassword = t( "invalidformate");
            } else {
              const { npassword, cnpassword } = inputValue;
              if (IsvalidateValue(password).length === 0 && IsvalidateValue(npassword).length == 0) {
                err_validate.password = "*";
                err_validate.npassword = "*";
                err_validate.allEmpty = t("AllFieldsMandatory");
              }
              else if (IsvalidateValue(npassword).length === 0) {
                err_validate.npassword = "*";
                err_validate.allEmpty = t("AllFieldsMandatory");
              } else {
                if (IsvalidateValue(npassword) !== IsvalidateValue(cnpassword)) {
                  err_validate.cnpassword = t("passwordnotmatch");
                }
              }
            }
          }
          break;
      }
    });

    err_validate.allEmpty = mandatory_validation(err_validate);
    setErrorSet({ ...errorSet, [errorSet]: err_validate });
    for (let i = 0; i < Object.keys(err_validate).length; i++) {
      if (Object.values(err_validate)[i].length > 0) {
        return false;
      }
    }
    return true;
  };

  const mandatory_validation = (error_validation) => {
    let msg = "";
    for (let x = 0; x < Object.keys(error_validation).length; x++) {
      if (Object.values(error_validation)[x] == "*" || msg.length) {
        return (msg = t("AllFieldsMandatory"));
      } else {
        msg = "";
      }
    }
    return msg;
  };

  const validate_strongPassword = (value) => {
    return validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
  };

  const IsvalidateValue = (_val) => {
    if (_val === undefined || _val === null || _val.length === 0) {
      return "";
    } else {
      return _val.trim();
    }
  }

  const ValidateCurrentPassword = () => {
    if (IsvalidateValue(inputValue.password).length > 0) {

      const body = {
        Email: inputValue.email.trim(),
        CurrentPassword: IsvalidateValue(inputValue.password)
      }

      httpService.Get(Employee_Api_EndPoints.VALIDATE_CURRENT_PASSWORD, body)
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data.isValid === false) {
              let server_errors = errorSet;
              server_errors.password = t("CurrentpasswordIncorrect");
              setErrorSet({ ...errorSet, [errorSet]: server_errors });
            } else {
              manageProfile();
            }
          }
        });
    } else {
      manageProfile();
    }
  }


  const UpdateUserProfile = () => {
    if (validate_field_errors()) {
      ValidateCurrentPassword();
    }
  }

  const manageProfile = () => {
    var body = {
      Id: Number(sessionStorage.UserId),
      FirstName: inputValue.firstName,
      LastName: inputValue.lastName,
      Email: inputValue.email,
      PhoneNumber: inputValue.phoneNumber,
      EmployeeId: inputValue.employeeId,
      Designation: IsvalidateValue(inputValue.designation),
      Workplace: IsvalidateValue(inputValue.workPlace),
      CurrentPassword: IsvalidateValue(inputValue.password),
      NewPassword: IsvalidateValue(inputValue.npassword),
      CPassword: IsvalidateValue(inputValue.cnpassword),
    };

    httpService
      .Post(Employee_Api_EndPoints.UPDATE_USERINFO, body)
      .then((resp) => {
        if (
          resp.code === "ERR_NETWORK" ||
          resp.code === "ERR_BAD_REQUEST"
        ) {
          if (resp.response.status === 400) {
            server_validations(resp.response.data.errors);
          }
          return false;
        }

        if (resp.data === "" && resp.status === 200) {
          getUserInfoByID();
          setInputValue({
            ...inputValue,
            password: "",
            npassword: "",
            cnpassword: ""
          });
          toast.success(t("updateusersuccessmsg"), {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      });
  }

  const server_validations = (error_key) => {
    let server_errors = errorSet;
    Object.keys(error_key).forEach((err_msg) => {
      switch (err_msg) {
        case "FirstName":
          server_errors.firstname = error_key[err_msg].toString();
          break;
        case "LastName":
          server_errors.lastname = error_key[err_msg].toString();
          break;
        case "CurrentPassword":
          server_errors.password = error_key[err_msg].toString();
          break;
        case "NewPassword":
          server_errors.npassword = error_key[err_msg].toString();
          break;
        case "CPassword":
          server_errors.cnpassword = error_key[err_msg].toString();
          break;
      }
    });
    setErrorSet({ ...errorSet, [errorSet]: server_errors });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleNewPassword = () => {
    setNewShowPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleEnter =
    (event) => {
      if (event.keyCode === 13) {
        UpdateUserProfile();
      }
    };

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          <div class="dashboard-container">
            <h2 class="page-module-heading mb-15">{t('UserPro')}</h2>

            <div onKeyDown={handleEnter}>
              <div class="user-form-container">
                <div class="profile-container">
                  <h2 class="form-heading">{t('UserPro')}</h2>
                  <div className="form-row">
                    {errorSet.firstName && (
                      <ErrorMessage message={errorSet.firstName} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="firstName"
                      value={firstName}
                      placeholder={t("FirstNamePlaceholder")}
                      icon={Name}
                      iconclassName="user-icon"
                      onChange={handleChange}
                      ErrorBorder="true"
                    />
                  </div>

                  <div className="form-row">
                    {errorSet.lastName === "" ? null : (
                      <ErrorMessage message={errorSet.lastName} />
                    )}

                    <InpuField
                      className="input-field"
                      type="text"
                      name="lastName"
                      value={lastName}
                      placeholder={t("LastNamePlcaeholder")}
                      icon={Name}
                      iconclassName={`user-icon`}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-row">
                    {errorSet.email === "" ? null : (
                      <ErrorMessage message={errorSet.email} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="email"
                      value={email}
                      placeholder={t("EmailPlacehlder")}
                      icon={Email}
                      iconclassName={`user-icon`}
                      onChange={handleChange}
                      ReadOnly={true}
                    />
                  </div>

                  <div className="form-row">
                    {errorSet.phoneNumber === "" ? null : (
                      <ErrorMessage message={errorSet.phoneNumber} />
                    )}
                    <div className="input-wrap">
                      <span class="input-field-icon-wrap">
                        <img src={Mobile} alt="" className="mobile-icon" />
                      </span>
                      <input
                      type="text"
                      icon={Mobile}
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => handleChange("phoneNumber", e)}
                      maxLength={15}
                      className="input-field"
                      placeholder="(123) 456-7890"
                      readOnly={true}
                    />
                    </div>
                  </div>

                  <div className="form-row">
                    <InpuField
                      className="input-field"
                      type="text"
                      name="workPlace"
                      value={workPlace}
                      placeholder={t("workplacePlaceholder")}
                      icon={Name}
                      iconclassName={`user-icon`}
                      onChange={handleChange}
                      ReadOnly={true}
                    />
                  </div>

                  <div className="form-row">
                    {errorSet.employeeId === "" ? null : (
                      <ErrorMessage message={errorSet.employeeId} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="employeeId"
                      value={employeeId}
                      placeholder={t("EmployeeIdPlcaholder")}
                      icon={User}
                      iconclassName={`user-icon`}
                      onChange={handleChange}
                      ReadOnly={true}
                    />
                  </div>

                  <div className="form-row">
                    <InpuField
                      className="input-field"
                      type="text"
                      name="designation"
                      value={designation}
                      placeholder={t("DesignationPlaceholder")}
                      icon={Name}
                      iconclassName={`user-icon`}
                      onChange={handleChange}
                      ReadOnly={true}
                    />
                  </div>
                </div>

                <div class="password-container">
                  <h2 class="form-heading">{t('UpdPswd')}</h2>

                  <div className="form-row">
                    {errorSet.password === "" ? null : (
                      <ErrorMessage message={errorSet.password} />
                    )}
                    <InpuField
                      className="input-field"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={password}
                      placeholder={t("CPasswordPlaceholder")}
                      icon={Lock}
                      iconclassName={`lock-icon`}
                      onChange={handleChange}
                    />
                    <button onClick={handleTogglePassword} className="showHidePassword">
                      {showPassword ? <img className="logo" src={viewPassword} alt="InstaDouch Logo" /> : <img className="logo" src={hidePassword} alt="InstaDouch Logo" />}
                    </button>
                  </div>

                  <div className="form-row">
                    {errorSet.npassword === "" ? null : (
                      <ErrorMessage message={errorSet.npassword} />
                    )}
                    <InpuField
                      className="input-field"
                      type={showNewPassword ? 'text' : 'password'}
                      name="npassword"
                      value={npassword}
                      placeholder={t("NPasswordPlaceholder")}
                      icon={Lock}
                      iconclassName={`lock-icon`}
                      onChange={handleChange}
                    />
                    <button onClick={handleToggleNewPassword} className="showHidePassword">
                      {showNewPassword ? <img className="logo" src={viewPassword} alt="InstaDouch Logo" /> : <img className="logo" src={hidePassword} alt="InstaDouch Logo" />}
                    </button>
                  </div>

                  <div className="form-row">
                    {errorSet.cnpassword === "" ? null : (
                      <ErrorMessage message={errorSet.cnpassword} />
                    )}
                    <InpuField
                      className="input-field"
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="cnpassword"
                      value={cnpassword}
                      placeholder={t("CnfPaawordPlaceholder")}
                      icon={Lock}
                      iconclassName={`lock-icon`}
                      onChange={handleChange}
                    />
                    <button onClick={handleToggleConfirmPassword} className="showHidePassword">
                      {showConfirmPassword ? <img className="logo" src={viewPassword} alt="InstaDouch Logo" /> : <img className="logo" src={hidePassword} alt="InstaDouch Logo" />}
                    </button>
                  </div>
                  <div className="form-row">
                    {errorSet.allEmpty === "" ? null : (
                      <ErrorMessage
                        className="validation-message-summary"
                        message={errorSet.allEmpty}
                      />
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div class="text-center">
              <Button
                label={t('Upd')}
                className="btn"
                onClick={UpdateUserProfile}
              />
            </div>

          </div>
        </Fragment>
      )}

      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default UpdateProfile;