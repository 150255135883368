import React, { Fragment, useState, useRef, useEffect } from "react";
import Button from "../components/common/button";
import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import LoadingSpinner from "../components/common/loadingSpinner";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import InpuField from "../components/common/input";
import viewPassword from "../assets/images/show.png";
import hidePassword from "../assets/images/hide.png";
import ErrorMessage from "../components/common/error_message";

function DoughCashOtherTransactions() {
  const changeVirtualCardPin = {
    cardNo: "",
    currentPin: "",
    newPin: "",
    confirmPin: ""
  }

  const didMount = useRef(false);
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(false);
  const [pinFlag, setPinFlag] = useState(true);
  const [cardStolenFlag, setCardStolenFlag] = useState(true);
  const [notifyFlag, setNotifyFlag] = useState(false);
  const [balance, setBalance] = useState(0);
  // const [pinValue, setPinValue] = useState("X X X X");
  const [inputPinValue, setInputPinValue] = useState(changeVirtualCardPin);
  const [showPin, setShowPin] = useState(false);
  const [showNewPin, setNewShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [pinError, setPinError] = useState({ cardno: "", currentpin: "", newpin: "", confirmpin: "" });
  const [IsValidPin, setIsValidPin] = useState(false);
  const [validPin, setValidPin] = useState(false);

  useEffect(() => {
    if (!didMount.current) {
      getBalance();
    }
    return () => {
      didMount.current = true;
    };
  });

  const getBalance = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService.Get(Employee_Api_EndPoints.GET_BALANCE, body).then((resp) => {
        if (resp.status == 200) {
          setBalance(resp.data.cardBalance);
        }
        setIsLoad(true);
      });
    } else {
      setIsLoad(true);
      userService.ClearSession();
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setInputPinValue(changeVirtualCardPin);
    setPinFlag(!pinFlag);
    setShowPin(false);
    setNewShowPin(false);
    setShowConfirmPin(false);
    setIsValidPin(false);
    setValidPin(false);
    // if (sessionStorage.UserId != undefined) {
    //   const body = {
    //     UserId: Number(sessionStorage.UserId),
    //   };
    //   httpService.Get(Employee_Api_EndPoints.GET_PIN, body).then((resp) => {
    //     if (resp.status == 200) {
    //       setPinValue(resp.data.pin);
    //       setPinFlag(!pinFlag);
    //     }
    //   });
    // } else {
    //   userService.ClearSession();
    // }
  };

  const handleStolenCard = (event) => {
    event.preventDefault();
    setCardStolenFlag(!cardStolenFlag);
  };

  const submitLostCard = (event) => {
    event.preventDefault();

    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService.Get(Employee_Api_EndPoints.REPORT_CARD, body).then((resp) => {
        if (resp.status == 200) {
          setPinFlag(false);
          setCardStolenFlag(false);
          setNotifyFlag(!notifyFlag);
        }
      });
    } else {
      userService.ClearSession();
    }
  };

  const handleMainAnctions = (event) => {
    event.preventDefault();
    setPinFlag(true);
    setCardStolenFlag(true);
    setNotifyFlag(false);
  };

  const pinChange = (key, value) => {
    if (value.length <= 8) {
      setInputPinValue((prevInputValue) => ({
        ...prevInputValue,
        [key]: value.replace(/[^0-9]/g, "")
      }));

      setPinError({ currentpin: "", newpin: "", confirmpin: "" });
    }
  }

  const handleCurrentPin = () => {
    setShowPin(!showPin);
  }

  const handleNewPin = () => {
    setNewShowPin(!showNewPin);
  }

  const handleConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  }

  const ValidatePIN = () => {
    let err_validate = pinError;
    const { cardNo, currentPin, newPin, confirmPin } = inputPinValue;
    Object.keys(inputPinValue).forEach((key) => {
      switch (key) {
        case "cardNo":
          if (!cardNo.trim()) {
            err_validate.cardno = "*";
          } else {
            if (cardNo.length < 16) {
              err_validate.cardno = "CardNo should be 16 digits";
            }
          }
          break;

        case "currentPin":
          if (!currentPin.trim()) {
            err_validate.currentpin = "*";
          }
          break;

        case "newPin":
          if (!newPin.trim()) {
            err_validate.newpin = "*";
          }
          break;

        case "confirmPin":
          if (!confirmPin.trim()) {
            err_validate.confirmpin = "*";
          }
          break;
      }
    });

    if (currentPin === newPin) {
      err_validate.newpin = "Current pin and new pin are matched. please modify."
    }

    if (newPin !== confirmPin) {
      err_validate.confirmpin = "Confirm pin doesn't matched with newPIN";
    }

    setPinError({ ...pinError, [pinError]: err_validate });
    for (let i = 0; i < Object.keys(err_validate).length; i++) {
      if (Object.values(err_validate)[i].length > 0) {
        return false;
      }
    }

    return true;
  }

  const ValidateOldOTP = () => {

    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        CardNo:inputPinValue.cardNo,
        CurrentPIN: inputPinValue.currentPin
      }

      httpService.Get(Employee_Api_EndPoints.VALIDATE_CURRENT_PIN, body)
        .then((resp) => {

          if (resp.data.isValid) {
            const body = {
              UserId: Number(sessionStorage.UserId),
              OldPIN: inputPinValue.currentPin,
              NewPIN: inputPinValue.newPin,
              ConfirmPIN: inputPinValue.confirmPin
            }

            httpService.Get(Employee_Api_EndPoints.GENETARE_NEW_PIN, body)
              .then((resp) => {

                if (resp.data.errorFound) {
                  setIsValidPin(true);
                  setValidPin(false);
                }
                else if (!resp.data.errorFound) {
                  setValidPin(true);
                  setIsValidPin(false);
                }

              });
          } else {
            if (!resp.data.isValid) {
              let err_validate = pinError;
              err_validate.currentpin = "Entered current pin is wrong.";
              setPinError({ ...pinError, [pinError]: err_validate });

              for (let i = 0; i < Object.keys(err_validate).length; i++) {
                if (Object.values(err_validate)[i].length > 0) {
                  return false;
                }
              }
            }
          }
        });
    }
  }

  const GetPinRequest = () => {

    if (sessionStorage.UserId != undefined) {
      if (ValidatePIN()) {

        ValidateOldOTP()

      }
    } else {
      userService.ClearCookies();
    }
  }

  const cc_format = (value) => {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
  }

  const checkDigit = (key, value) => {
    if (value.replace(/[^0-9]/g, "").length <= 16) {
      setInputPinValue((prevInputValue) => ({
        ...prevInputValue,
        [key]: value.replace(/[^0-9]/g, "")
      }));

      setPinError({ cardno: "" });
    }
  }

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          <div class="dashboard-container">
            <NavLink to="/doughcard" className="back-link">
              <i class="arrow left"></i>{t("backbutton")}
            </NavLink>
            <h2 class="page-module-heading mb-15">{t("doughcardothertrs")}</h2>
            <div class="your-dough-card-continer">
              {/* <!-- card inner start --> */}
              <div class="summary-card-inner">
                {/* <!-- card details start --> */}
                <div class="summary-card-details">
                  <h2 class="card-heading">{t("doughcardsummary")}</h2>
                  <h2 class="wages-heading">{t("doughcardWagestoAccess")}</h2>
                  <h2 class="wage-amount">$ {balance.toFixed(2)}</h2>
                  <h3 class="wage-card-number">**** **** **** 1234</h3>
                  <div class="card-holder-details-provider">
                    <div class="card-holder-details">
                      <div class="valid">
                        {t("Valid Thru")}
                        <span>08/28</span>
                      </div>
                      <div class="valid">
                        {t("Card holder")}
                        <span>{sessionStorage?.UserName}</span>
                      </div>
                    </div>
                    <div class="card-provider-symbol">
                      <img src="./images/card-provider-logo.svg" alt="" />
                    </div>
                  </div>
                </div>
                {/* <!-- card details end --> */}
              </div>
              {/* <!-- card inner end --> */}
            </div>
            <h2 class="page-module-heading">
              {t("Youhave")}{" "}
              <span class="color-white">$ {balance.toFixed(2)}</span> {t("yourDoughCard")}
            </h2>
            {pinFlag && cardStolenFlag && (
              <div class="paydata-home mt-30">
                <Button
                  className="btn btn-paydata"
                  label={t("changepin")}
                  value="doughcardpin"
                  onClick={handleChange}
                />
                <Button
                  className="btn btn-paydata"
                  label="Report Card Lost or Stolen"
                  value="reportForLost"
                  onClick={handleStolenCard}
                />
              </div>
            )}

            {!pinFlag && cardStolenFlag && (
              <div class="paydata-home switched-content">
                <div class="form-row show-password pl-30">
                  <NavLink className="back-link" onClick={handleChange}>
                    <i class="arrow left"></i> {t("backbutton")}
                  </NavLink>
                </div>
                {IsValidPin && <>
                  <h2 className="text-center color-white">
                    {t("YourDoughCardPINFalse")}
                  </h2>
                  {/* <h2 className="text-center color-white">{pinValue}</h2> */}
                </>
                }
                {validPin && <>
                  <h2 className="text-center color-white">
                    {t("YourDoughCardPINTrue")}
                  </h2>
                </>}
                {!IsValidPin && !validPin && <>

                  <div className="form-row">
                    {pinError.cardno === "" ? null : (
                      <ErrorMessage message={pinError.cardno} />
                    )}
                    <InpuField type="text" name="cardNo" placeholder="1234 1234 1234 1234" value={cc_format(inputPinValue.cardNo)} onChange={checkDigit} />
                  </div>

                  <div className="form-row">
                    {pinError.currentpin === "" ? null : (
                      <ErrorMessage message={pinError.currentpin} />
                    )}
                    <InpuField type={showPin ? "text" : "password"} className="input-field" name="currentPin" value={inputPinValue.currentPin} placeholder="Current Pin" onChange={pinChange} />
                    <button onClick={handleCurrentPin} className="showHidePassword">
                      {showPin ? <img className="logo" src={viewPassword} alt="InstaDouch Logo" /> : <img className="logo" src={hidePassword} alt="InstaDouch Logo" />}
                    </button>
                  </div>

                  <div className="form-row">
                    {pinError.newpin === "" ? null : (
                      <ErrorMessage message={pinError.newpin} />
                    )}
                    <InpuField type={showNewPin ? "text" : "password"} className="input-field" name="newPin" value={inputPinValue.newPin} placeholder="New Pin" onChange={pinChange} />
                    <button onClick={handleNewPin} className="showHidePassword">
                      {showNewPin ? <img className="logo" src={viewPassword} alt="InstaDouch Logo" /> : <img className="logo" src={hidePassword} alt="InstaDouch Logo" />}
                    </button>
                  </div>

                  <div className="form-row">
                    {pinError.confirmpin === "" ? null : (
                      <ErrorMessage message={pinError.confirmpin} />
                    )}
                    <InpuField type={showConfirmPin ? "text" : "password"} className="input-field" name="confirmPin" value={inputPinValue.confirmPin} placeholder="Confirm Pin" onChange={pinChange} />
                    <button onClick={handleConfirmPin} className="showHidePassword">
                      {showConfirmPin ? <img className="logo" src={viewPassword} alt="InstaDouch Logo" /> : <img className="logo" src={hidePassword} alt="InstaDouch Logo" />}
                    </button>
                  </div>

                  <Button
                    label={t("Confirm")}
                    value="confirm_btn"
                    className="btn"
                    onClick={GetPinRequest}
                  />

                </>}
              </div>
            )}
            {!cardStolenFlag && pinFlag && (
              <div class="paydata-home switched-content">
                <h2 className="text-center color-white">
                  {t("othertrscardstolenmsg")}
                </h2>
                <Button
                  label={t("Confirm")}
                  value="confirm_btn"
                  className="btn"
                  onClick={submitLostCard}
                />
                <div class="form-row show-password pl-30">
                  <NavLink href="" className="back-link" onClick={handleStolenCard}>
                    <i class="arrow left"></i> {t("backbutton")}
                  </NavLink>
                </div>
              </div>
            )}
            {notifyFlag && (
              <div class="paydata-home switched-content">
                <h2 className="text-center color-white">
                  {t("notifycarderr1")}
                </h2>
                <h2 className="text-center color-white">
                  {t("notifycarderr2")}
                </h2>
                <h2 className="text-center color-white"> {t("notifycarderr3")}</h2>
                <div class="form-row show-password pl-30">
                  <NavLink
                    class="color-primary-blue"
                    onClick={handleMainAnctions}
                  >
                    &lt; {t("backbutton")}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          {/* <!-- dashboard main end --> */}
        </Fragment>
      )}
      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default DoughCashOtherTransactions;
