import React, { Fragment, useEffect, useRef, useState } from "react";
import InpuField from "../components/common/input";
import Button from "../components/common/button";
// import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import ErrorMessage from "../components/common/error_message";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPInput from "otp-input-react";
import logo from "../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function AddBankAccounts() {
  const didMount = useRef(false);
  const { t } = useTranslation();
  const [addAccount, setAddAccount] = useState({
    BankId: "",
    AccountName: "",
    AccountDisplayName: "",
    Achrouting: "",
    TypeId: 2,
    AccountNumber: "",
    ConfirmAccountNumber: "",
  });
  const [editAccount, setEditAccount] = useState({
    id: 0,
    bankId: "",
    accountName: "",
    accountDisplayName: "",
    achrouting: "",
    typeId: 2,
    accountNumber: "",
  });
  const [errorMsg, setErrrMsg] = useState({
    BankId: "",
    AccountName: "",
    AccountDisplayName: "",
    Achrouting: "",
    AccountNumber: "",
    ConfirmAccountNumber: "",
  });
  const [editact_errorMsg, setEditactMsg] = useState({
    bankId: "",
    accountName: "",
    accountDisplayName: "",
    achrouting: "",
  });
  const [userAccounts, setUserAccounts] = useState([]);
  const [accoutType, setAccountType] = useState();
  const [bankList, setBankList] = useState();
  const [editDetails, setEditDetails] = useState(false);
  const [OTP, setOTP] = useState("");
  const [overView, setOverView] = useState(false);
  const [addEditAccout, setaddEditAccount] = useState("add");
  const [optLength] = useState(6);
  const [InvalidMsg] = useState("Invalid OTP");
  const [IsValidOTP, setIsValidOTP] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [achResponse, setAchResponse] = useState(true);

  useEffect(() => {
    if (!didMount.current) {
      getAccountType();
      getBankList();
      getUserAccounts();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const handlePopupClick = (event) => {
    event.preventDefault();
    setShowPopup(!showPopup);
    setOverView(false);
    getUserAccounts();
    clear_fileds();
  };

  const validateOtp = (key) => {
    setOTP(key);
    if (key.length === optLength) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        ValidateOtp: key,
      };

      httpService.Get(Employee_Api_EndPoints.VALIDATE_OTP, body).then(
        (resp) => {
          if (!resp.data.isvalid) {
            setIsValidOTP(false);
            setOTP("");
          } else {
            if (addEditAccout === "add") {
              addUserBankAccount();
            } else if (addEditAccout === "edit") {
              updatehandleClick();
            }
            setOTP("");
            setOverView(resp.data.isvalid);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const getUserAccounts = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
      };
      httpService
        .Get(Employee_Api_EndPoints.GET_USER_ACCOUNTS, body)
        .then((resp) => {
          setUserAccounts(resp.data);
        });
    }
  };

  const handleChange = (key, value) => {

    if (
      key === "AccountNumber" ||
      key === "ConfirmAccountNumber"
    ) {
      value = value.replace(/\D/g, "");
    }

    if (key === "Achrouting") {
      if (value.replace(/[^0-9]/g, "").length <= 9) {
        value = value.replace(/[^0-9]/g, "")
      }
      else {
        return;
      }
    }

    setAddAccount((prev) => ({ ...prev, [key]: value }));

    setErrrMsg((err) => ({ ...err, [key]: "" }));
  };

  const edithandleChange = (key, value) => {
    if (key === "achrouting") {
      value = value.replace(/\D/g, "");

      if (value.replace(/[^0-9]/g, "").length <= 9) {
        value = value.replace(/[^0-9]/g, "")
      }
      else {
        return;
      }
    }

    setEditAccount((prev) => ({ ...prev, [key]: value }));

    setEditactMsg((err) => ({ ...err, [key]: "" }));
  };

  const handleSelectChange = (event) => {
    setAddAccount((prev) => ({ ...prev, BankId: event.target.value }));
    setErrrMsg((err) => ({ ...err, BankId: "" }));
  };

  const edithandleSelectChange = (event) => {
    setEditAccount((prev) => ({ ...prev, bankId: event.target.value }));
    setEditactMsg((err) => ({ ...err, bankId: "" }));
  };

  const handleAccountTypeChange = (event) => {
    setAddAccount((prev) => ({
      ...prev,
      TypeId: accoutType.find((x) => x.type === event.target.name).id,
    }));
  };

  const edithandleAccountTypeChange = (event) => {
    setEditAccount((prev) => ({
      ...prev,
      typeId: accoutType.find((x) => x.type === event.target.name).id,
    }));
  };

  const getAccountType = () => {
    httpService.Get(Employee_Api_EndPoints.GET_ACCOUNT_TYPES).then((resp) => {
      setAccountType(resp.data);
    });
  };

  const getBankList = () => {
    httpService.Get(Employee_Api_EndPoints.GET_BANK_LIST).then((resp) => {
      setBankList(resp.data);
    });
  };

  const addUserBankAccount = () => {

    if (validate_error()) {
      createBankAccount();
    }

  };

  const validateBankAccount = () => {
    var body = {
      UserId: Number(sessionStorage.UserId),
      AccountNumber: addAccount.AccountNumber
    }

    httpService.Get(Employee_Api_EndPoints.VALIDATE_BANK_ACCOUNT, body)
      .then((resp) => {
        if (resp.data.errorFound === true && resp.data.errorCode === "Account_Number") {
          setErrrMsg({ AccountNumber: "Account exists." });

        } else {
          if (resp.data.errorFound === false) {
            generateOTP();
          }
        }
      });
  }

  const createBankAccount = () => {
    var body = {
      UserId: Number(sessionStorage.UserId),
      BankId: Number(addAccount.BankId),
      AccountName: addAccount.AccountName,
      AccountDisplayName: addAccount.AccountDisplayName,
      Achrouting: Number(addAccount.Achrouting),
      TypeId: Number(addAccount.TypeId),
      AccountNumber: addAccount.AccountNumber,
    };

    httpService
      .Get(Employee_Api_EndPoints.ADD_BANKACCOUNT, body)
      .then((resp) => {

        if (resp.data.errorFound === true) {
          setEditDetails(false);

          toast.error(resp.data.errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });

        } else {

          if (resp.data.errorFound === false && resp.data.errorMessage == "NO_CARD") {

            setShowPopup(true);
            setAchResponse(false);

          } else if (resp.data.errorFound === false && resp.data.errorMessage == "SERVICE_RESPONSE_NULL") {

            setShowPopup(true);
            setAchResponse(true);

          } else if (resp.data.errorFound === false) {

            toast.success("User Account Added Successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });

            setOverView(false);
            getUserAccounts();
            clear_fileds();
          }
        }
      });

  }



  const clear_fileds = () => {
    const fields = addAccount;
    fields.BankId = "";
    fields.AccountName = "";
    fields.AccountDisplayName = "";
    fields.Achrouting = "";
    fields.TypeId = 2;
    fields.AccountNumber = "";
    fields.ConfirmAccountNumber = "";
    setAddAccount((clr) => ({ ...clr, addAccount: fields }));
  };

  const validate_error = () => {
    const errorkeys = errorMsg;

    Object.keys(addAccount).forEach((key) => {
      switch (key) {
        case "AccountName":
          if (!addAccount.AccountName.trim()) {
            errorkeys.AccountName = "*";
          } else {
            if (addAccount.AccountName.length < 2) {
              errorkeys.AccountName = t("Invalidentry");
            }
          }
          break;
        case "AccountDisplayName":
          if (!addAccount.AccountDisplayName.trim()) {
            errorkeys.AccountDisplayName = "*";
          } else {
            if (addAccount.AccountDisplayName.length < 2) {
              errorkeys.AccountDisplayName = t("Invalidentry");
            }
          }
          break;

        case "Achrouting":
          if (!addAccount.Achrouting.trim()) {
            errorkeys.Achrouting = "*";
          } else {
            if (addAccount.Achrouting.length < 9) {
              errorkeys.Achrouting = t("Invalidentry");
            }
          }
          break;

        case "AccountNumber":
          if (!addAccount.AccountNumber.trim()) {
            errorkeys.AccountNumber = "*";
          }
          break;

        case "ConfirmAccountNumber":
          if (!addAccount.ConfirmAccountNumber.trim()) {
            errorkeys.ConfirmAccountNumber = "*";
          } else {
            if (
              addAccount.AccountNumber.trim() !==
              addAccount.ConfirmAccountNumber.trim()
            ) {
              errorkeys.ConfirmAccountNumber =
                t("AccountNumberandConfirmAccountNumberdonotmatcherr");
            }
          }
          break;

        case "BankId":
          if (!addAccount.BankId.trim()) {
            errorkeys.BankId = "*";
          }
          break;
      }
    });

    setErrrMsg((err) => ({ ...err, errorMsg: errorkeys }));

    for (let i = 0; i < Object.keys(errorkeys).length; i++) {
      if (Object.values(errorkeys)[i].length > 0) {
        return false;
      }
    }
    return true;
  };

  const editAccountDetails = (e) => {
    const accountDetails = userAccounts.find(
      (x) => x.id === Number(e.target.value)
    );
    setEditAccount({ ...accountDetails });
    setEditDetails(true);
  };

  const deleteAccountDetails = (e) => {
    e.preventDefault();

    setEditDetails(false);

    var body = {
      Id: Number(e.target.value),
    };

    httpService
      .Post(Employee_Api_EndPoints.DELETE_BANKACCOUNT, body)
      .then((resp) => {
        if (resp.code === "ERR_BAD_RESPONSE" && resp.response.status === 500) {
          toast.success(
            t("Deletemsg"),
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            }
          );
        } else {
          if (resp.status === 200) {
            getUserAccounts();
            toast.success(t("Deletesuccessmsg"), {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        }
      });
  };

  const switchToAddAccount = () => {
    setEditDetails(false);
  };

  const editError_validate = () => {
    const editerrorKeys = editact_errorMsg;
    Object.keys(editAccount).forEach((key) => {
      switch (key) {
        case "accountName":
          if (!editAccount.accountName.trim()) {
            editerrorKeys.accountName = "*";
          } else {
            if (editAccount.accountName.length < 2) {
              editerrorKeys.accountName = t("Invalidentry");
            }
          }
          break;

        case "accountDisplayName":
          if (!editAccount.accountDisplayName.trim()) {
            editerrorKeys.accountDisplayName = "*";
          } else {
            if (editAccount.accountDisplayName.length < 2) {
              editerrorKeys.accountDisplayName = t("Invalidentry");
            }
          }
          break;

        case "achrouting":
          if (editAccount.achrouting === "") {
            editerrorKeys.achrouting = "*";
          } else {
            if (editAccount.achrouting.length > 9) {
              editerrorKeys.achrouting = t("Invalidentry");
            }
          }
          break;

        case "bankId":
          if (editAccount.bankId === "") {
            editerrorKeys.bankId = "*";
          }
          break;
      }
    });

    setEditactMsg((err) => ({ ...err, editact_errorMsg: editerrorKeys }));
    for (let i = 0; i < Object.keys(editerrorKeys).length; i++) {
      if (Object.values(editerrorKeys)[i].length > 0) {
        return false;
      }
    }
    return true;
  };

  const updatehandleClick = () => {
    if (editError_validate()) {
      var body = {
        Id: Number(editAccount.id),
        UserId: Number(sessionStorage.UserId),
        BankId: Number(editAccount.bankId),
        AccountName: editAccount.accountName,
        AccountDisplayName: editAccount.accountDisplayName,
        Achrouting: Number(editAccount.achrouting),
        TypeId: Number(editAccount.typeId),
        AccountNumber: editAccount.accountNumber,
      };

      httpService
        .Post(Employee_Api_EndPoints.UPDATE_BANKACCOUNT, body)
        .then((resp) => {
          if (resp.status === 200) {
            setOverView(false);
            switchToAddAccount();
            getUserAccounts();
            toast.success(t("UserAccountUpdatedSuccessfully"), {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        });
    }
  };

  const switchToOTP = () => {

    if (validate_error()) {
      validateBankAccount();
    }

  };

  const generateOTP = () => {
    if (sessionStorage.UserId != undefined) {

      const body = {
        UserId: Number(sessionStorage.UserId),
      };

      httpService.Post(Employee_Api_EndPoints.GENERATE_OTP, body)
        .then((resp) => {

          if (resp.status === 200) {
            setaddEditAccount("add");
            setOverView(true);
          } else {
            setOverView(false);
          }

        }, (err) => {
          console.log("Error", err);
          setOverView(false);

        })
    }
  }

  const switchToEditOtp = () => {
    if (editError_validate()) {

      if (sessionStorage.UserId != undefined) {

        const body = {
          UserId: Number(sessionStorage.UserId),
        };

        httpService.Post(Employee_Api_EndPoints.GENERATE_OTP, body)
          .then((resp) => {

            if (resp.status === 200) {

              setaddEditAccount("edit");
              setOverView(true);

            } else {
              setOverView(false);
            }

          }, (err) => {
            console.log("Error", err);
            setOverView(false);

          })
      }
    }
  };

  return (
    <Fragment>
      <div className="dashboard-container">
        <Link to="/achtransaction" className="back-link">
          <i class="arrow left"></i> {t("backbutton")}
        </Link>
        {!overView && (
          <Fragment>
            {userAccounts.length > 0 && (
              <div className="dashboard-container">
                <div className="table-container">
                  <table className="pay-info-table">
                    <thead>
                      <tr>
                        <th>{t("AccountNo")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    {userAccounts.map((lst, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{lst.accountNumber}</td>
                            <td>
                              <div className="table-action-btns-container">
                                <button
                                  className="action-btn"
                                  value={lst.id}
                                  onClick={editAccountDetails}
                                >
                                  {t("Edit")}
                                </button>
                                <button
                                  className="action-btn"
                                  value={lst.id}
                                  onClick={deleteAccountDetails}
                                >
                                  {t("Delete")}
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>
                </div>

                {/* <Button label="Edit" className="btn btn-login" value={lst.id} onClick={editAccountDetails} /> */}
                {/* <Button label="Delete" className="btn btn-login" value={lst.id} onClick={deleteAccountDetails} /> */}
              </div>
            )}

            {!editDetails && accoutType && bankList && (
              <Fragment>
                <div className="dashboard-container">
                  <div className="form-row">
                    {errorMsg.AccountName === "" ? null : (
                      <ErrorMessage message={errorMsg.AccountName} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="AccountName"
                      value={addAccount.AccountName}
                      placeholder={t("AccountNameplaceholder")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    {errorMsg.AccountDisplayName === "" ? null : (
                      <ErrorMessage message={errorMsg.AccountDisplayName} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="AccountDisplayName"
                      value={addAccount.AccountDisplayName}
                      placeholder={t("AccountDisplayNameplaceholder")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    {errorMsg.Achrouting === "" ? null : (
                      <ErrorMessage message={errorMsg.Achrouting} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="Achrouting"
                      value={addAccount.Achrouting}
                      placeholder={t("AccountACHRoutingNumberplaceholder")}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="form-row"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <label className="radiobtn-container">
                      {t("Checking")}
                      <input
                        type="radio"
                        name="Checking"
                        value={addAccount.TypeId}
                        checked={addAccount.TypeId === 1 ? true : false}
                        onChange={handleAccountTypeChange}
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label className="radiobtn-container">
                      {t("Saving")}
                      <input
                        type="radio"
                        name="Savings"
                        value={addAccount.TypeId}
                        checked={addAccount.TypeId === 2 ? true : false}
                        onChange={handleAccountTypeChange}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-row">
                    {errorMsg.AccountNumber === "" ? null : (
                      <ErrorMessage message={errorMsg.AccountNumber} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="AccountNumber"
                      value={addAccount.AccountNumber}
                      placeholder={t("AccountNumberplaceholder")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    {errorMsg.ConfirmAccountNumber === "" ? null : (
                      <ErrorMessage message={errorMsg.ConfirmAccountNumber} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="ConfirmAccountNumber"
                      value={addAccount.ConfirmAccountNumber}
                      placeholder={t("ConfirmAccountNumberplaceholder")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    {errorMsg.BankId === "" ? null : (
                      <ErrorMessage message={errorMsg.BankId} />
                    )}
                    <select
                      value={addAccount.BankId}
                      name="BankId"
                      className="bankaccount-selection"
                      onChange={handleSelectChange}
                    >
                      <option value="">{t("SelectBank")}</option>
                      {bankList.map((list, index) => {
                        return (
                          <option key={index} value={list?.id}>
                            {list?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-row">
                    {/* <Button label="Add Account" className="btn btn-login" onClick={handleClick} /> */}
                    <Button
                      label={t("addaccount")}
                      className="btn btn-login"
                      onClick={switchToOTP}
                    />
                  </div>
                </div>
              </Fragment>
            )}

            {editDetails && (
              <Fragment>
                <div className="dashboard-container">
                  <h1 className="page-module-heading mb-15">{t("EditBankdetails")}</h1>
                  <div className="form-row">
                    {editact_errorMsg.accountName === "" ? null : (
                      <ErrorMessage message={editact_errorMsg.accountName} />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="accountName"
                      value={editAccount.accountName}
                      placeholder={t("AccountNameplaceholder")}
                      onChange={edithandleChange}
                    />
                  </div>

                  <div className="form-row">
                    {editact_errorMsg.accountDisplayName === "" ? null : (
                      <ErrorMessage
                        message={editact_errorMsg.accountDisplayName}
                      />
                    )}
                    <InpuField
                      className="input-field"
                      type="text"
                      name="accountDisplayName"
                      value={editAccount.accountDisplayName}
                      placeholder={t("AccountDisplayNameplaceholder")}
                      onChange={edithandleChange}
                    />
                  </div>

                  <div className="form-row">
                    {editact_errorMsg.achrouting === "" ? null : (
                      <ErrorMessage message={editact_errorMsg.achrouting} />
                    )}
                    <InpuField
                      className="input-field"
                      ReadOnly={true}
                      type="text"
                      name="achrouting"
                      value={editAccount.achrouting}
                      placeholder={t("AccountACHRoutingNumberplaceholder")}
                      onChange={edithandleChange}
                    />
                  </div>

                  <div
                    className="form-row"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <label className="radiobtn-container">
                      {t("Checking")}
                      <input
                        type="radio"
                        name="Checking"
                        value={editAccount.typeId}
                        checked={editAccount.typeId === 1 ? true : false}
                        onChange={edithandleAccountTypeChange}
                        className="rdb-bankaccount"
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label className="radiobtn-container">
                      {t("Saving")}
                      <input
                        type="radio"
                        name="Savings"
                        value={editAccount.typeId}
                        checked={editAccount.typeId === 2 ? true : false}
                        onChange={edithandleAccountTypeChange}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div className="form-row">
                    <InpuField
                      ReadOnly={true}
                      className="input-field"
                      type="text"
                      name="accountNumber"
                      value={editAccount.accountNumber}
                      placeholder={t("AccountNumberplaceholder")}
                    />
                  </div>

                  <div className="form-row">
                    {editact_errorMsg.bankId === "" ? null : (
                      <ErrorMessage message={editact_errorMsg.bankId} />
                    )}
                    <select
                      value={editAccount.bankId}
                      name="bankId"
                      className="bankaccount-selection"
                      onChange={edithandleSelectChange}
                    >
                      <option value="">{t("SelectBank")}</option>
                      {bankList.map((list, index) => {
                        return (
                          <option key={index} value={list?.id}>
                            {list?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div
                    className="form-row"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    {/* <Button label="Update" className="btn btn-login" onClick={updatehandleClick} /> */}
                    <Button
                      label="Update"
                      className="btn btn-login"
                      onClick={switchToEditOtp}
                    />
                    <Button
                      label="Cancel"
                      className="btn btn-login"
                      onClick={switchToAddAccount}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
        {overView && (
          <div class="login-form flex-justify-content-space-between" style={{ margin: "auto" }}>
            <div class="logo">
              <img src={logo} alt="InstaDouch Logo" />
            </div>
            <div className="otp-wrap">
              <div className="otp-message">
                {t("otplabel")}
              </div>
              <OTPInput
                value={OTP}
                onChange={(otp) => validateOtp(otp)}
                autoFocus
                OTPLength={optLength}
                otpType="number"
                disabled={false}
                secure
              />
              { }
              <div> {!IsValidOTP && <div className="form-row"> <ErrorMessage message={InvalidMsg} /> </div>}</div>
            </div>
          </div>
        )}
        {showPopup && (
          <>
            <div className="popup-backdrop"></div>
            <div className="popup">
              <div className="popup-header">
                <h2 className="popup-heading">{t("AccountInfo")}</h2>
                <button onClick={handlePopupClick} className="close"></button>
              </div>

              <div className="popup-body">
                {!achResponse &&
                  <p>Congratulations! Your account has been successfully added to our service.
                    To enable further transactions, we kindly request you to obtain a card.
                    Please reach out to the relevant authority to receive your card and unlock the
                    full potential of our services. we appreciate your cooperation and look forward
                    to serving you.
                  </p>
                }
                {achResponse &&
                  <p>Congratulations! Your account has been successfully added to our service.
                    To enable further transactions, Please reach out to the relevant authority.
                    There is some technical issue. we appreciate your cooperation and look forward
                    to serving you.
                  </p>
                }
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
}
export default AddBankAccounts;
