import { environment } from "./environment";

export class Employee_Api_EndPoints {
    private static get API_BASE_URL() { return environment.employee_api_base_url; }
    public static get CREATE_USERINFO() { return this.API_BASE_URL + 'UserProfile/CreateEmployee'; }
    public static get AUTHORIZATION() { return this.API_BASE_URL + 'Authorization/GetAccessToken'; }
    public static get LOGOUT() { return this.API_BASE_URL + 'Logout/LogoutAccount'; }
    public static get EARNED_POINTS() { return this.API_BASE_URL + 'RafilEarned/GetBreadPoints'; }
    public static get VALIDATE_USER_INFO() { return this.API_BASE_URL + 'CustomValidate/ValidateInfo'; }
    public static get DOUGH_SUMMARY() { return this.API_BASE_URL + 'CashSummary/GetSummary'; }
    public static get GENERATE_TIMESHEET() { return this.API_BASE_URL + 'TimeSheet/GetTimesheets'; }
    public static get GENERATE_TIP_COMPONENT() { return this.API_BASE_URL + 'TipComponent/GetTips'; }
    public static get PAYDATA_TIMESHEET() { return this.API_BASE_URL + 'TimeSheet/GetDataTimeSheet'; }
    public static get PAYDATA_TIPSHEET() { return this.API_BASE_URL + 'TipComponent/GetPayDataTips'; }
    public static get GET_USER_INFO() { return this.API_BASE_URL + 'UserProfile/GetUserInfo'; }
    public static get UPDATE_USERINFO() { return this.API_BASE_URL + 'UserProfile/UpdateUserInfo'; }
    public static get FILE_UPLOAD() { return this.API_BASE_URL + 'FileUpload/UploadImage'; }
    public static get GET_PROFILE_PIC() { return this.API_BASE_URL + 'FileUpload/GetImage'; }
    public static get GET_BALANCE() { return this.API_BASE_URL + 'EmployeeDoughCardInfo/GetBalance'; }
    public static get GET_PIN() { return this.API_BASE_URL + 'EmployeeDoughCardInfo/GetPin'; }
    public static get REPORT_CARD() { return this.API_BASE_URL + 'EmployeeDoughCardInfo/ReportLostStolenCard'; }
    public static get TRANSFER_BALANCE() { return this.API_BASE_URL + 'BalanceTransfer/TransferableAmount'; }
    public static get GET_ACCOUNT_TYPES() { return this.API_BASE_URL + 'BankAccount/GetAccountTypes'; }
    public static get GET_BANK_LIST() { return this.API_BASE_URL + 'BankAccount/GetBankList'; }
    public static get ADD_BANKACCOUNT() { return this.API_BASE_URL + 'BankAccount/CreateBankAccount'; }
    public static get GET_USER_ACCOUNTS() { return this.API_BASE_URL + 'BankAccount/GetBankAccountDetails'; }
    public static get UPDATE_BANKACCOUNT() { return this.API_BASE_URL + 'BankAccount/UpdateAccount'; }
    public static get DELETE_BANKACCOUNT() { return this.API_BASE_URL + 'BankAccount/DeleteAccount'; }
    public static get GET_TRANSACTION_DETAILS() { return this.API_BASE_URL + 'BalanceTransfer/GetTransactionInfo'; }
    public static get GET_USER_CARDS() { return this.API_BASE_URL + 'CardTransfer/GetUserCards'; }
    public static get CARD_TRANSACTION() { return this.API_BASE_URL + 'CardTransfer/DoughCardTransfer'; }
    public static get GET_CARD_TRANSACTION() { return this.API_BASE_URL + 'CardTransfer/GetCardTransactions'; }
    public static get GET_DoughDepo_TRANSACTION() { return this.API_BASE_URL + 'CardTransfer/GetDoughDepoTransactions'; }
    public static get VALIDATE_OTP() { return this.API_BASE_URL + 'Otp/GetOTP'; }
    public static get GENERATE_OTP() { return this.API_BASE_URL + 'Generateotp/GenerateOTP'; }
    public static get VALIDATE_CURRENT_PASSWORD() { return this.API_BASE_URL + 'UserProfile/ValidateCurrentPwd'; }
    public static get GET_ACH_FEE() { return this.API_BASE_URL + 'ACHFee/GetACHFee'; }
    public static get GET_VIRTUAL_CARD_TOKEN() { return this.API_BASE_URL + 'VirtualCard/GetVirtualCardInfo'; }
    public static get GENETARE_NEW_PIN() { return this.API_BASE_URL + 'ChangeVirtualCardPin/GetVirtualPinChange'; }
    public static get GET_TEMPLATE_WITH_CODE() { return this.API_BASE_URL + 'Template/GetTemplateWithCode'; }
    public static get VALIDATE_CURRENT_PIN() { return this.API_BASE_URL + 'ChangeVirtualCardPin/ValidateOldCurrentPIN'; }
    public static get VALIDATE_BANK_ACCOUNT() { return this.API_BASE_URL + 'BankAccount/ValidateBankAccount'; }
}