// import React, { Fragment, useRef, useState, useEffect } from "react";
import React, { Fragment, useState, useEffect, useRef } from "react";
import profilePic from "../../assets/images/profile-pic.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as httpService from "../../services/httpService";
import { Employee_Api_EndPoints } from "../../global/end-points";
import * as userService from "../../utils/userService";

function Header() {
  const location = useLocation();
  const { t } = useTranslation();
  const fileRef = useRef();
  const didMount = useRef(false);
  // const [currentPath] = useState(location.pathname.substring(1) === 'updateprofile' ? true : false);
  // const [currentPath, setCurrentPath] = useState(
  //   location.pathname.substring(1) === "updateprofile" ? true : false
  // );

  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [imageDataUrl, setImageDataUrl] = useState(profilePic);
  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
  };

  // useEffect(() => {
  //   setCurrentPath(
  //     location.pathname.substring(1) === "updateprofile" ? true : false
  //   );
  //   const storedLanguage = localStorage.getItem("selectedLanguage");
  //   if (storedLanguage && storedLanguage !== selectedLanguage) {
  //     setSelectedLanguage(storedLanguage);
  //     i18n.changeLanguage(storedLanguage);
  //   }
  // }, [location.pathname]);

  // const fileRef = useRef();
  // const didMount = useRef(false);

  // useEffect(() => {
  //   if (!didMount.current) {
  //     setCurrentPath(location.pathname.substring(1) === 'updateprofile' ? true : false);
  //   }
  //   return () => {
  //     didMount.current = true;
  //   };
  // }, []);

  useEffect(() => {
    if (!didMount.current) {
      // setCurrentPath(
      //   location.pathname.substring(1) === "updateprofile" ? true : false
      // );
      const storedLanguage = localStorage.getItem("selectedLanguage");
      if (storedLanguage && storedLanguage !== selectedLanguage) {
        setSelectedLanguage(storedLanguage);
        i18n.changeLanguage(storedLanguage);
      }
      getProfilePic();
    }
    return () => {
      didMount.current = true;
    };
  }, [location.pathname]);

  const handleFileChange = async (event) => {
    event.preventDefault();

    if (sessionStorage.UserId != undefined) {
      var formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("userId", Number(sessionStorage.UserId));

      fetch(Employee_Api_EndPoints.FILE_UPLOAD, {
        method: "POST",
        body: formData,
        headers: {
          AccessToken: userService.GetUserToken(),
        },
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Success:", response);
            getProfilePic();
          } else {
            console.error("Error:", response);
          }
          fileRef.current.value = null;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userService.ClearCookies();
    }
  };

  const getProfilePic = () => {
    if (sessionStorage.UserId != undefined) {
      var body = {
        UserId: Number(sessionStorage.UserId),
      };

      httpService.Get(Employee_Api_EndPoints.GET_PROFILE_PIC, body).then(
        (resp) => {
          if (resp.code === "ERR_BAD_REQUEST") {
            setImageDataUrl(profilePic);
          } else {
            if (resp.data?.message !== undefined) {
              setImageDataUrl(profilePic);
            } else {
              console.log("Sucess", resp);
              var info = "data:image/png;base64," + resp.data;
              setImageDataUrl(info);
            }
          }
        },
        (err) => {
          console.log("Error", err);
        }
      );
    } else {
      userService.ClearCookies();
    }
  };

  return (
    <Fragment>
      {/* <div className="dashboard-right"> */}
      <div className="dashboard-header">
        <h2 className="page-heading">{t("dashboard")}</h2>
        <div className="languages-container">
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="language-selection"
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        <div className="user-profile">
          <span>{sessionStorage.UserName ?? "jone doe"}</span>
          <div className="user-profile-pic">
            <div className="user-profile-pic-inner">
              <img src={imageDataUrl} alt="" />
            </div>

            {/* <img src={profilePic} alt="" /> */}

            <div className="profile-menu-container">
              <Link to="/updateprofile" className="profile-menu-item">
                {t("ChangeProfile")}
              </Link>
              <div className="profile-menu-item">
                <input
                  type="file"
                  name="file"
                  id="file"
                  class="inputfile"
                  onChange={handleFileChange}
                  ref={fileRef}
                />
                <label for="file">{t("ChangeAvtar")}</label>
              </div>
              {/* <Link to="#" className="profile-menu-item">
                Change Avtar
              </Link> */}
              <Link to="/logout" className="profile-menu-item">
                {t("Logout")}
              </Link>
            </div>
          </div>

          {/* {currentPath && (
            <span className="change-avtar-btn">Change Avatar</span>
          )} */}
          {/* <input type="file" onChange={handleFileChange} ref={fileRef} /> */}
        </div>
      </div>
      {/* </div> */}
    </Fragment>
  );
}
export default Header;
