import React, { Fragment,useEffect,useState } from "react";
import Cookies from "universal-cookie";
import logo from "../assets/images/logo.svg";

function FAQs() {
    const [isload, setLoad] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);


  useEffect(() => {
    // if (!didMount.current) {
    const cookies = new Cookies();
    const _loginForm = cookies.get("loginForm");
    if (_loginForm !== undefined) {
      // Object.keys(_loginForm).forEach(cookieName => {
      //   inputValue[cookieName] = _loginForm[cookieName];
      // });

      
    }
    // setInputValue({ ...inputValue });
    setLoad(true);
    // }
    // return () => {
    //   didMount.current = true;
    // };
  }, []);

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };
       

  return (
    <Fragment>
      {" "}
      {isload && (
        <>
          <div className="login-page-header">
            <a href="/" className="logo-container">
              <img src={logo} alt="InstaDouch Logo" className="logo" />
            </a>
            <nav>
            <a href="/homepage" className="nav-menu-item">
                Home
              </a>
              <a href="/company" className="nav-menu-item">
                Company
              </a>
              <a href="/employers" className="nav-menu-item">
                Employers
              </a>
              <a href="/employees" className="nav-menu-item">
                Employees
              </a>
              <a href="/fAQs" className="nav-menu-item login-link">
                FAQs
              </a>
              <a href="/" className="nav-menu-item">
                Login
                <div className="login-dropdown-container">
                  <a href="#" className="login-dropdown-link-item">
                    Employee Login
                  </a>
                  <a href="#" className="login-dropdown-link-item">
                    Employer Login
                  </a>
                </div>
              </a>
            </nav>
          </div>
            {/* Login screen end  */}
            <div className="faqs-content">
            <h2 className="faqshead">Frequently Asked Questions (FAQs)</h2>
            {faqData.map((faq, index) => (
              <div className="faq-item" key={index}>
                <div className="question" onClick={() => toggleQuestion(index)}>
                  <h3>{faq.question}</h3>
                  <span className={`arrow ${activeQuestion === index ? "active" : ""}`}>&#9660;</span>
                </div>
                {activeQuestion === index && (
                   <div className="answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </Fragment>
  );
}

export default FAQs;

const faqData = [
    {
      question: " Why do employers want to have instant pay for their employees?",
      answer: "Employers may want to offer instant pay to their employees for several reasons:<br><br>" +
      "<strong>Employee Retention and Satisfaction:</strong> Offering instant pay can be seen as an employee benefit that can boost satisfaction and help retain employees, especially in competitive job markets or industries with high turnover rates. Instant pay gives employees access to their earned wages when they need it, which can ease financial stress and foster greater job satisfaction.<br><br>" +
      "<strong>Attracting Talent:</strong> As more businesses move towards offering instant pay, it may be seen as a competitive edge when attracting new talent. Prospective employees who are considering multiple job offers may be swayed by the benefit of instant pay.<br><br>" +
      "<strong>Financial Well-being of Employees:</strong> Many employees live paycheck to paycheck, and unexpected expenses can cause significant financial stress. Instant pay allows employees to access their earned wages when emergencies arise, which can help them avoid late fees, overdraft charges, and high-interest short-term loans.<br><br>" +
      "<strong>Increased Productivity:</strong> Some studies have suggested that financial stress can have a negative impact on productivity. By providing instant pay, employers may help reduce financial stress among their employees, which in turn could potentially increase productivity.<br><br>" +
      "<strong>Flexibility:</strong> For businesses with irregular hours or gig work, instant pay can offer greater flexibility to workers. This can be particularly appealing for gig workers, freelancers, or part-time workers.<br><br>" +
      "However, it's important to note that instant pay is not without challenges. These can include administrative and financial complexities, as well as potential for misuse if employees do not manage their finances effectively. It's important for companies to consider these factors carefully when deciding whether to offer instant pay.",
    },
    {
      question: "What are the best instant pay companies?",
      answer: "There are several companies that are well-known for offering instant pay solutions. Here are a few examples:<br><br>" +
      "<strong>InstaDough:</strong> <br><br>" +
      "<strong>DailyPay:</strong> DailyPay partners with businesses to allow their employees to receive earned income before the scheduled payday. Employees can track their accumulated earnings during each pay period and can transfer funds to their bank accounts whenever needed.<br><br>" +
      "<strong>PayActiv:</strong> PayActiv offers an earned wage access platform that provides financial services for employees, including instant access to earned but unpaid wages. PayActiv also provides additional services like electronic bill pay, savings tool, and financial health counseling.<br><br>" +
      "<strong>Even:</strong> This financial planning app, which partners with employers, provides a number of features to employees including the ability to access earned wages early. It also helps employees with budgeting and saving.<br><br>" +
      "<strong>Branch:</strong> Branch is a mobile-first technology that provides schedule flexibility, financial health, and instant payments to hourly employees. Employees can receive a portion of their paycheck instantly for hours they have worked.<br><br>" +
      "<strong>Earnin:</strong> Earnin is a bit different as it’s a personal finance app that allows users to access their earned income prior to payday. There's no fee or interest, instead users are asked to tip what they think is fair.<br><br>" +
      "<strong>FlexWage:</strong> FlexWage provides a variety of financial solutions including instant access to earned wages. It also offers payroll cards to help those who don't have traditional banking access.<br><br>" +
      "Remember to carefully consider the pros and cons of each service before deciding which one is the best fit for your company. Some may have additional fees or require certain business sizes or types.",
  },
    {
        question: "What do you need to consider when selecting an instant pay company?",
        answer:
          "When selecting an instant pay company, there are several factors to consider:<br><br>" +
          "<strong>Fees:</strong> What are the costs associated with the service, both for the company and for the employees? Some services may charge fees for transactions or may pass costs onto employees.<br><br>" +
          "<strong>Integration:</strong> How well does the service integrate with your existing payroll system? A good solution should be able to work seamlessly with the systems you already have in place.<br><br>" +
          "<strong>Ease of Use:</strong> How easy is it for employees to use the service? The user interface should be intuitive and straightforward, and the process of accessing earned wages early should be simple and hassle-free.<br><br>" +
          "<strong>Customer Support:</strong> Good customer service is essential. If there are issues or questions, you'll want to know there is a reliable customer support team ready to assist.<br><br>" +
          "<strong>Regulatory Compliance:</strong> Ensure the company complies with all relevant labor laws and regulations. Non-compliance can result in fines and legal issues.<br><br>" +
          "<strong>Security:</strong> Look into the company's security measures to protect financial data. A good instant pay company should employ top-tier encryption and security measures to keep your and your employees' information safe.<br><br>" +
          "<strong>Additional Services:</strong> Some companies may offer additional services beyond instant pay, such as budgeting tools, savings accounts, or financial counseling. These additional features can be valuable to employees.<br><br>" +
          "<strong>Reputation and Reviews:</strong> Look for a company with a solid reputation. Read reviews from other businesses that have used their services.<br><br>" +
          "<strong>Employer Control:</strong> Some platforms allow employers to control features like how often employees can access instant pay, and what percentage of their paycheck can be accessed early. If this control is important for your business, it's a factor to consider.<br><br>" +
          "By considering these factors, you can choose an instant pay company that aligns with your business's needs and goals, as well as the needs of your employees.",
    },
    {
        question: "How do most instant wage companies make money?",
        answer:
          "Most instant wage access companies generate revenue through various models:<br><br>" +
          "<strong>Transaction Fees:</strong> Some companies charge transaction fees every time an employee accesses their wages early. These fees are typically small but can add up with frequent use.<br><br>" +
          "<strong>Subscription Fees:</strong> Some instant pay companies operate on a subscription-based model, where they charge employers a monthly or annual fee for their services.<br><br>" +
          "<strong>Employer-Paid Fees:</strong> In some cases, employers pay the fees associated with the service as an employee benefit. The fee structure and amount can vary widely from one provider to another.<br><br>" +
          "<strong>Voluntary Tips:</strong> Some platforms, like Earnin for instance, allow users to pay what they believe is fair in the form of a tip for the service, instead of charging a fixed fee.<br><br>" +
          "<strong>Partnerships and Integrations:</strong> Instant pay companies may also earn revenue through partnerships with other businesses or by offering integrations with existing HR/payroll systems.<br><br>" +
          "<strong>Additional Financial Services:</strong> Many instant pay companies offer other financial services such as budgeting tools, saving accounts, debit cards, and financial counseling. They may charge fees for these additional services or earn interest from the deposited funds.<br><br>" +
          "It's important to understand how an instant pay company generates its revenue so you can assess whether their model aligns with your company's budget and philosophy, and how it could potentially impact your employees' finances.",
    },
    {
        question: "How does instant pay contribute to employee retention?",
        answer:
          "Instant pay can contribute to employee retention in several ways:<br><br>" +
          "<strong>Alleviating Financial Stress:</strong> With instant pay, employees can access their wages as soon as they earn them, providing them with greater financial flexibility. This can help alleviate financial stress, which is often a cause of distraction, low morale, and decreased productivity.<br><br>" +
          "<strong>Increased Employee Satisfaction:</strong> Having the option to access pay immediately can improve overall job satisfaction. Employees may feel more valued and taken care of when their employer offers such a benefit.<br><br>" +
          "<strong>Competitive Advantage:</strong> In job markets where potential employees have multiple options, the offer of instant pay can make an employer more attractive. This can not only help in attracting talent but also in retaining employees who might otherwise be tempted to leave for other opportunities.<br><br>" +
          "<strong>Reducing Turnover:</strong> High turnover rates can be costly for businesses. Providing instant pay may lead to higher retention rates, thus saving the costs associated with hiring and training new employees.<br><br>" +
          "<strong>Building Trust:</strong> By offering instant pay, employers show they trust their employees and respect their financial needs. This trust can foster a stronger relationship between the employer and employee, thereby increasing loyalty and reducing turnover.<br><br>" +
          "However, it's essential that instant pay is part of a comprehensive employee benefits package. Other factors like career development opportunities, positive workplace culture, competitive salaries, and good management practices are also crucial for employee retention.",
    },
    {
        question: "How do instant pay companies train employees to use their solution?",
        answer:
          "Training is an essential part of introducing any new software or service into a company, and that includes instant pay solutions. Most instant pay companies offer a range of training and support services to help businesses and their employees understand and use their products effectively. Here's how it typically works:<br><br>" +
          "<strong>Onboarding Sessions:</strong> When a company signs up for an instant pay service, the service provider will often hold an onboarding session. This session is designed to familiarize the company's HR and payroll staff with the system and its features.<br><br>" +
          "<strong>User Manuals and Guides:</strong> Companies typically provide detailed user manuals and guides that explain how to use their services. These may be in the form of written documents, online guides, or video tutorials.<br><br>" +
          "<strong>Online Training:</strong> Some companies offer online training modules that employees can complete at their own pace. These modules may cover everything from the basics of how to access their pay to more advanced features.<br><br>" +
          "<strong>Customer Support:</strong> Good instant pay companies will have a customer support team on standby to help resolve any issues that employees may encounter when using the service. This could be through email, phone, or live chat support.<br><br>" +
          "<strong>In-app Guidance:</strong> Many apps have built-in guidance and help features that can assist users in navigating the platform and making use of its features.<br><br>" +
          "<strong>Webinars or In-Person Training:</strong> Depending on the size of the business and the complexity of the service, some companies may offer webinars or even in-person training sessions to help employees understand how to use the service.<br><br>" +
          "Remember that the goal of these training resources is to ensure that employees feel comfortable and confident in using the service, understand its benefits, and know who to contact if they have any questions or concerns.",
    },
    {
        question: "How much money can employers save using instant pay?",
        answer:
        "Implementing instant pay can potentially result in cost savings for employers, though the exact amount can vary widely depending on a number of factors. Here are a few ways how instant pay could contribute to cost savings:<br><br>" +
        "<strong>Reduced Turnover Costs:</strong> High employee turnover can be expensive, with costs including recruitment, training, and lost productivity during the transition period. If instant pay increases employee satisfaction and retention, as some studies suggest, it could potentially reduce these turnover-related costs.<br><br>" +
        "<strong>Increased Productivity:</strong> Financial stress can have a negative impact on productivity. If instant pay can help alleviate financial stress for employees, it could result in increased productivity and thus potentially higher revenue for the company.<br><br>" +
        "<strong>Attracting Talent:</strong> By offering instant pay as a benefit, companies may be able to attract higher-quality candidates, potentially leading to a more effective and efficient workforce.<br><br>" +
        "<strong>Reduced Absenteeism:</strong> Financial difficulties can sometimes lead to increased absenteeism, as employees may need to take time off to deal with financial issues or might take up a second job. By alleviating financial stress, instant pay might reduce absenteeism, saving costs associated with it.<br><br>" +
        "However, it's important to note that there are also costs associated with implementing and managing an instant pay system. These can include service fees, administrative costs, and the time required to train employees to use the system. These costs need to be factored in when calculating the potential savings of implementing instant pay.<br><br>" +
        "Moreover, while instant pay can be a valuable benefit, it's only one part of a comprehensive approach to improving employee satisfaction and retention. Other factors such as competitive salaries, good management practices, opportunities for career advancement, and a positive workplace culture are also crucial.",
      },
      {
        question: "What industries is instant pay most prevalent?",
        answer: `
      Instant pay solutions have been gaining traction in several industries, particularly those where workers often live paycheck to paycheck or where income may fluctuate due to irregular hours. Here are a few of the industries where instant pay is most prevalent:<br><br>

      <strong>Retail:</strong> Retail workers often work irregular hours, particularly during peak shopping seasons. Instant pay can provide these employees with quicker access to their earnings.<br><br>

      <strong>Hospitality:</strong> In the hospitality industry, workers often face irregular hours and fluctuating income, especially in roles that rely on tips. Instant pay can provide financial stability in this unpredictable work environment.<br><br>

      <strong>Gig Economy:</strong> For gig workers, who may work for multiple platforms or employers, instant pay can help smooth out income volatility. Companies like Uber and Lyft, for example, offer features that allow drivers to get paid instantly.<br><br>

      <strong>Healthcare:</strong> Healthcare, particularly home health care and nursing, is another industry where instant pay has become popular. These roles often involve irregular hours and shift work, making instant pay a valuable benefit.<br><br>

      <strong>Construction:</strong> Workers in construction might face fluctuating work hours depending on the project. Instant pay can provide these workers with more financial flexibility.<br><br>

      <strong>Food Service:</strong> Restaurant workers, particularly servers and bartenders, often rely on tips for a significant portion of their income. Being able to access their wage instantly can provide financial stability.<br><br>

      Keep in mind that while these industries have seen wider adoption, instant pay can potentially be beneficial in any industry, especially if the workforce includes part-time workers, hourly workers, or workers with irregular schedules. Always, it is recommended to evaluate the specific needs and circumstances of your workforce when considering implementing an instant pay system.
    `,
      },
      {
        question: "What are the compliance considerations for employers regarding instant pay?",
    answer: `
      Offering instant pay or earned wage access (EWA) can bring several compliance considerations for employers:<br><br>

      <strong>Wage and Hour Laws:</strong> Employers must ensure that instant pay practices comply with federal, state, and local wage and hour laws, including minimum wage requirements and overtime calculations. For instance, fees associated with accessing earned wages should not result in an employee receiving less than the minimum wage.<br><br>

      <strong>Truth in Lending Act (TILA):</strong> While EWA programs may not be considered loans in some instances, if they are, the Truth in Lending Act could apply, which requires disclosures about costs and terms.<br><br>

      <strong>State Payday Laws:</strong> Employers must comply with state laws governing pay frequency, methods of payment, and final paycheck rules. Some states have specific rules about granting wage advances or deductions, which could potentially apply to EWA programs.<br><br>

      <strong>Recordkeeping:</strong> Employers need to keep accurate records of hours worked and wages paid. If an instant pay system is used, employers must ensure they can still fulfill these obligations.<br><br>

      <strong>Tax Obligations:</strong> Taxes must still be properly calculated and withheld, even if employees are accessing their wages early.<br><br>

      <strong>Consumer Protection Laws:</strong> Depending on how the EWA program is structured, various consumer protection laws may apply. For instance, if the EWA provider is considered a third-party lender, the program must comply with laws related to lending and debt collection.<br><br>

      <strong>Contractual Agreements:</strong> If there are contractual agreements regarding pay frequency or timing, these need to be respected unless they are revised.<br><br>

      <strong>Employee Privacy:</strong> EWA programs often require sharing employee information with the provider. Employers must ensure this sharing complies with privacy laws and that the provider has adequate security measures in place to protect the information.<br><br>

      Because of these complexities, it's advisable for employers to consult with legal counsel or a knowledgeable HR professional to ensure compliance when implementing an EWA program.
    `,
      },
      {
        question: "How does an instant pay software integrate with your payroll?",
    answer: `
      Instant pay software typically integrates with a company's existing payroll system in order to facilitate real-time or near-real-time wage access. Here's a general outline of how this works:<br><br>

      <strong>Data Access:</strong> The instant pay software needs to have access to your payroll data. This includes details about each employee's hours worked, wage rate, and accrued earnings. The software uses this data to calculate how much each employee has earned and can therefore access before the next scheduled payday.<br><br>

      <strong>Data Sync:</strong> The system typically syncs with your payroll data regularly (often in real-time or daily) to ensure that the information about available wages is up-to-date.<br><br>

      <strong>Transfers:</strong> When an employee chooses to access their wages early, the instant pay software facilitates the transfer of funds from the employer's bank account to the employee's bank account. Some systems might advance the funds themselves and then reconcile the amounts when payroll is processed.<br><br>

      <strong>Reconciliation:</strong> When payday arrives, the payroll system takes into account any wages that have already been paid out through the instant pay software, so that the employee receives the remainder of their earnings.<br><br>

      <strong>Tax and Deductions:</strong> The system still ensures that appropriate tax withholdings and any other payroll deductions are taken into account. It's crucial that this aspect is managed correctly to maintain compliance with tax laws and regulations.<br><br>

      It's important to note that the specifics can vary from one instant pay provider to another, and integration might be more seamless with certain payroll systems compared to others. Before implementing instant pay, you should discuss the integration process in detail with the provider to understand how it will work with your existing systems and ensure it meets your needs. Also, you should ensure that the provider has robust security measures in place to protect your payroll data.
    `,
      },
  
    // Add more FAQs as needed
  ];
  