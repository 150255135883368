import React, { Fragment, useEffect, useRef, useState } from "react";
// import Iframe from 'react-iframe';
import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import LoadingSpinner from "../components/common/loadingSpinner";

function VirtualCardComponent() {
    const [isLoad, setIsLoad] = useState(false);
    const [errorFound, setErrorFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [cardInfo, setCardInfo] = useState({});
    const didMount = useRef(false);

    useEffect(() => {
        if (!didMount.current) {
            getVirtualCardToken();
        }
        return () => {
            didMount.current = true;
        };
    }, []);

    const getVirtualCardToken = () => {
        if (sessionStorage.UserId != undefined) {
            const body = {
                UserId: Number(sessionStorage.UserId),
            };
            httpService.Get(Employee_Api_EndPoints.GET_VIRTUAL_CARD_TOKEN, body)
                .then((resp) => {
                    let errorFound = false;
                    let errorMessage = '';
                    if (resp.data.errorFound === true &&
                        (resp.data.errorCode === "USER_NOT_FOUND" || resp.data.errorCode === "USER_ADDRESS_NOT_FOUND"
                            || resp.data.errorCode === "PRODUCTID_NOT_FOUND" || resp.data.errorCode === "REQUEST_NOT_ACCEPTED"
                            || resp.data.errorCode === "REQUEST_FAILED")) {

                        errorFound = resp.data.errorFound;
                        errorMessage = 'We have encourted technical issue and virtual card can not be generated. Please try again later';

                    } else if (resp.data.errorFound === false) {
                        errorFound = resp.data.errorFound;
                        errorMessage = '';
                        setCardInfo(resp.data);
                    }

                    setErrorFound(errorFound);
                    setErrorMessage(errorMessage);
                    setIsLoad(true);
                    console.log(resp.data);
                }, (err) => {
                    console.log(err);
                })
        } else {
            userService.ClearSession();
        }
    }

    return (
        <Fragment>
            {isLoad &&
                <Fragment>
                    {!errorFound &&
                        // <Iframe url={cardInfo.url}
                        //     width="640px"
                        //     height="320px"
                        //     id="1"
                        //     className=""
                        //     display="block"
                        //     position="relative"
                        // />
                        <h2 className="text-center color-white">Please click the <a  href={cardInfo.url} target="_blank"> <h2> link </h2> </a>to access the virtual card. </h2>
                    }
                    {errorFound &&
                        <div class="paydata-home switched-content">
                            <h2 className="text-center color-white">{errorMessage}</h2>
                        </div>
                    }
                </Fragment>
            }
            {!isLoad && <LoadingSpinner />}
        </Fragment>
    )

}
export default VirtualCardComponent;