import React, { useState } from "react";
import OTPInput from "otp-input-react";
import logo from "../assets/images/logo.svg";
import Overview from "../views/overview";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import ErrorMessage from "../components/common/error_message";

const OTP = (props) => {
  const [OTP, setOTP] = useState("");
  const [overView, setOverView] = useState(false);
  const [optLength] = useState(6);
  const [InvalidMsg] = useState("Invalid OTP");
  const [IsValidOTP, setIsValidOTP] = useState(true);

  const validateOtp = (key) => {
    setOTP(key);
    if (key.length === optLength) {
      const body = {
        UserId : 0,
        PhoneNumber : props.signUpInfo.mobile,
        ValidateOtp: key
      }

      httpService.Get(Employee_Api_EndPoints.VALIDATE_OTP, body)
        .then((resp) => {
          if (!resp.data.isvalid) {
            setIsValidOTP(false);
            setOTP("");
          }
          setOverView(resp.data.isvalid);
        }, (err) => {
          console.log(err);
        })
    }
  };

  return (
    <>
      {!overView && (
        <div class="login-form flex-justify-content-space-between">
          <div class="logo">
            <img src={logo} alt="InstaDouch Logo" />
          </div>
          <div className="otp-wrap">
            <div className="otp-message">
              Enter 6 Digit OTP sent to your mobile
            </div>
            <OTPInput
              value={OTP}
              onChange={(otp) => validateOtp(otp)}
              autoFocus
              OTPLength={optLength}
              otpType="number"
              disabled={false}
              secure
            />
            { }
            <div> {!IsValidOTP && <div className="form-row"> <ErrorMessage message={InvalidMsg} /> </div>}</div>
          </div>
         
        </div>
      )}
      
      {overView && <Overview UserInfo={props?.signUpInfo} />}
    </>
  );
};
export default OTP;
