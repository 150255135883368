import React, { Fragment } from "react";

const ErrorMessage = ({ message }) => {
  return (
    <Fragment>
      <div className="validation-message">
        {message && <span>{message}</span>}
      </div>
    </Fragment>
  );
};

export default ErrorMessage;
