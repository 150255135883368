import React, { Fragment, useRef, useEffect, useState } from "react";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import * as userService from "../utils/userService";
import LoadingSpinner from "../components/common/loadingSpinner";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";

function Logout() {
  const [isLoad, setIsLoad] = useState(false);
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      fnLogout();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const fnLogout = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        AccessToken: sessionStorage.AccessToken,
      };

      httpService.Post(Employee_Api_EndPoints.LOGOUT, body).then(
        (resp) => {
          if (resp.status === 200) {
            setIsLoad(true);
          } else {
            window.location.href = "/";
          }

          localStorage.clear();
          sessionStorage.clear();
          window.localStorage.clear();
        },
        (err) => {
          console.log(err);
          userService.ClearCookies();
        }
      );
    } else {
      userService.ClearCookies();
    }
  };

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          <div className="container">
            <div className="dashboard-container">
              <div className="logo mb-20">
                <img src={logo} alt="InstaDouch Logo" />
              </div>
              <h5 className="text-center color-white mb-5">
                You successfully loggedout
              </h5>
              <h1 className="text-center color-orange mb-10">See you soon!</h1>
              <p className="text-center color-primary-blue mb-20">
                Please click below link, it will take to login screen
              </p>
              <h4 className="color-white text-center">
                Want to login?{" "}
                <Link to="/" className="color-orange text-decoration-none">
                  Click here
                </Link>{" "}
                to go home page
              </h4>
            </div>
          </div>
        </Fragment>
      )}
      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default Logout;
