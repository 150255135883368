import React, { Fragment, useEffect, useRef } from "react";
import Button from "../components/common/button";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from "../assets/images/logo.svg";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/common/loadingSpinner";
import parse from 'html-react-parser';
import ExceptionPopup from '../components/common/exceptionhandle';

const Overview = (props) => {
  let navigate = useNavigate();
  const didMount = useRef(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);
  const [isLoad, setIsLoad] = useState(true);
  const [termsContent, setTermsContent] = useState('');
  const [cardActivate, setCardActivate] = useState(false);
  const [isException, setIsException] = useState(false);
  const [exceptionMsg, setExceptionMsg] = useState({ title: "warning", content: "This is a sample content" });

  useEffect(() => {
    if (!didMount.current) {
      getTemplate();
    }
    return () => {
      didMount.current = true;
    };
  }, []);


  const getTemplate = () => {

    let body = {
      Code: "TERMSCONDITIONS"
    }

    httpService.Get(Employee_Api_EndPoints.GET_TEMPLATE_WITH_CODE, body)
      .then((resp) => {
        console.log(resp.data.errorFound);
        if (!resp.data.errorFound) {
          setTermsContent(resp.data.templateHtml);
        }
        setIsLoad(false);
      });
  }

  const handlePopupClick = (event) => {
    event.preventDefault();
    setShowPopup(!showPopup);
  };

  const activateCard = () => {
    if (!isButtonDisabled) {
      if (props?.UserInfo != undefined) {
        let inputValue = props?.UserInfo;
        const body = {
          FirstName: inputValue.firstname,
          LastName: inputValue.lastname,
          Password: inputValue.password,
          CPassword: inputValue.cpassword,
          Email: inputValue.email,
          PhoneNumber: inputValue.mobile,
          EmployeeId: inputValue.employeeid,
        };
        setCardActivate(true);
        httpService.Get(Employee_Api_EndPoints.CREATE_USERINFO, body).then(
          (resp) => {

            if (resp.data.errorFound === true && resp.data.errorCode === 'EMPLOYEE_EXISTS') {
              setExceptionMsg({ title: "Warning", content: "Employee Exists!, Please contact with administrative team. Thank you!" });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === 'EXCEPTION_ERROR') {
              setExceptionMsg({ title: "Error", content: "Employee registration has been successfully created. " + resp.data.errorMessage + <br /> + "Please contact with administrative team. Thank you!" });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === "DOUGHCMUSER_ZIP") {
              setExceptionMsg({ title: "Warning", content: "Employee registration has been successfully created. " + " Unable to find user address. We are unable to create the Payment account. Please contact with support team." });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === "PAYMENT_ACCOUNT_NOT_CREATED") {
              setExceptionMsg({ title: "Warning", content: "Employee registration has been successfully created. " + " Paymemt account not created. please wait for 2 days support team will get back to you." });
              setIsException(true);
            } else if (resp.data.errorFound === true && resp.data.errorCode === "PAYMENT_ACCOUNT_RESPONSE_FAILED") {
              setExceptionMsg({ title: "Warning", content: "Employee registration has been successfully created. " + <br /> + resp.data.errorCode + " : " + resp.data.errorMessage });
              setIsException(true);
            } else {
              if (resp.data.errorFound === false && resp.data.errorCode === '') {
                const tokenKeys = {
                  UserName: inputValue.email,
                  Password: inputValue.password,
                };
                httpService
                  .Get(Employee_Api_EndPoints.AUTHORIZATION, tokenKeys)
                  .then(
                    (resp) => {
                      if (
                        resp.status == 200 &&
                        resp.data != null &&
                        resp.data.userID > 0
                      ) {
                        sessionStorage.setItem(
                          "AccessToken",
                          resp.data?.accessToken
                        );
                        sessionStorage.setItem("UserName", resp.data?.userName);
                        sessionStorage.setItem("UserId", resp.data?.userID);
                        navigate("/home");
                      }
                    },
                    () => {
                      setCardActivate(false);
                      return false;
                    }
                  );
              }
            }

          },
          (err) => {
            setCardActivate(false);
            console.log(err);
          }
        );
      }
    } else {
      if (!checkbox1 && !checkbox2) {
        setError(
          "Please Agree to Subscription Fee & Terms / Conditions to Proceed"
        );
      } else if (!checkbox1) {
        setError("Please Agree to Subscription Fee to Proceed");
      } else {
        setError("Please Agree Terms / Conditions to Proceed");
      }
      return;
    }
    setError(null);
  };
  const isButtonDisabled = !checkbox1 || !checkbox2;

  const handleCheckbox1Change = (event) => {
    setCheckbox1(event.target.checked);
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2(event.target.checked);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsException(false);
    navigate('/');
  };

  return (
    <Fragment>
      {!isLoad && (
        <div className="container">
          <div class="signup-form">
            <div class="logo mt-40">
              <img src={logo} alt="InstaDouch Logo" />
            </div>

            <h2 class="login-register-form-heading mt-40 mb-20">
              Program <span>Overview</span>
            </h2>

            <div class="form-row">
              <p class="text-center color-white">
                DoughCash is designed to give you early access to your hard earned
                money. So that you can take home the bread!
                <br />
                <br />
                Accept our minimal monthly subscription fee and get instant access
                to your Dough!
              </p>
              <p class="text-center mt-50 mb-50 color-orange">
                <b>$39.99/Month Subscription</b>
              </p>
            </div>

            <div class="form-row show-password">
              <input
                type="checkbox"
                checked={checkbox1}
                onChange={handleCheckbox1Change}
              />
              I accept monthly subscription fee
            </div>

            <div class="form-row show-password pl-30">
              <NavLink class="color-primary-blue" onClick={handlePopupClick}>
                Terms & Conditions
              </NavLink>
            </div>

            <div class="form-row show-password">
              <input
                type="checkbox"
                checked={checkbox2}
                onChange={handleCheckbox2Change}
              />
              I have read and agree to the Terms & Conditions
            </div>

            <div class="form-row mt-60">
              {!cardActivate && <Button
                label="Accept"
                className="btn btn-login"
                onClick={activateCard}
              />}
              {cardActivate && <LoadingSpinner />}
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>

          {showPopup && (
            <>
              <div className="popup-backdrop"></div>
              <div className="popup">
                <div className="popup-header">
                  <h2 className="popup-heading">Terms & Conditions</h2>
                  <button onClick={handlePopupClick} className="close"></button>
                </div>

                <div className="popup-body">
                  {parse(termsContent)}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {isLoad && <LoadingSpinner />}
      {isException && <ExceptionPopup title={exceptionMsg.title} content={exceptionMsg.content} onClose={handleClosePopup} />}
    </Fragment>
  );
};
export default Overview;
