import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import logo from "../assets/images/logo.svg";

function Company() {
  const [isload, setLoad] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const _loginForm = cookies.get("loginForm");
    if (_loginForm !== undefined) {
      // Do something with _loginForm if needed
    }
    setLoad(true);
  }, []);

  return (
    <Fragment>
      {isload && (
        <>
          <div className="login-page-header">
            <a href="/" className="logo-container">
              <img src={logo} alt="InstaDouch Logo" className="logo" />
            </a>
            <nav>
            <a href="/homepage" className="nav-menu-item">
                Home
              </a>
              <a href="/company" className="nav-menu-item login-link">
                Company
              </a>
              <a href="/employers" className="nav-menu-item">
                Employers
              </a>
              <a href="/employees" className="nav-menu-item">
                Employees
              </a>
              <a href="/fAQs" className="nav-menu-item">
                FAQs
              </a>
              <a href="/" className="nav-menu-item">
                Login
                <div className="login-dropdown-container">
                  <a href="#" className="login-dropdown-link-item">
                    Employee Login
                  </a>
                  <a href="#" className="login-dropdown-link-item">
                    Employer Login
                  </a>
                </div>
              </a>
            </nav>
          </div>

          <div className="paragraph-container-left">
            <p>
              InstaDough’s Earned Wage Access (EWA) product, DoughPay was
              designed to help free employees from the shackles of waiting
              weeks to access their hard-earned money. Inflation and wealth
              disparity have hindered many families. Our product is built to
              give financial freedom back to hard working employees.
            </p><br />
            <p>
              InstaDough’s founders are small business owners, many starting
              from nothing and working hard every day to inch forward. Many of
              us stem from the restaurant and hospitality industries. We know
              how seasonal and volatile earnings can be. Additionally, we know
              that life happens, expenses arise that you were not planning.
            </p><br />
            <p>This is why we founded InstaDough and built the DoughPay Product!</p>
          </div>

          <div className="paragraph-container-right">
          <h4>InstaDough’s core operational principles stem from:</h4><br />
             <ul>
                <li>Integrity – Always taking the morally ethical path.</li><br />
                 <li>Transparency – We operate in full transparency, displaying our weaknesses and strengths, we believe this provides for the best relationships.</li><br />
                 <li>Security – Protecting our clients and their information that they trust us with is imperative and entrenched in our operation.</li><br />
                 <li>Speed – We operate every day with the utmost urgency, we know how short life is and how many demands everyone faces, we don’t have time to spare!</li><br />
            </ul>
          </div>
        </>
      )}
    </Fragment>
  );
}

export default Company;
