// import React, { useState, useEffect } from 'react';
import React, { useEffect } from "react";
// import logo from './logo.svg';
// import * as httpService from './services/httpService';
// import { Employee_Api_EndPoints } from './global/end-points';
import "./App.css";
import RouterConfig from "./utils/routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./language/i18n";
// import logo from "./assets/images/logo.svg";

function App() {
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   getEmployeeDetails();
  // });

  // function getEmployeeDetails() {
  //   httpService.Get(Employee_Api_EndPoints.GET_EMPLOYEE_DETAILS).then((resp) => {
  //     setData(resp);
  //   }, (err) => {
  //     console.log(err);
  //   })
  // }

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ToastContainer />
        <RouterConfig />
      </I18nextProvider>
    </>
  );
}

export default App;
