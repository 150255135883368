import React, { Fragment } from "react";
import Button from "../components/common/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function PaydataSheet() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const submitButton = (event) => {
        event.preventDefault();
        switch (event.target.value) {
            case 'hours':
                return navigate("/paytimesheet");
            case 'tips':
                return navigate("/paytipsheet");
        }
    }

    return (
        <Fragment>
            <div className="dashboard-container">
            <div class="paydata-home">
                <Button
                    label={t('Hours')}
                    value="hours"
                    className="btn btn-paydata"
                    onClick={submitButton}
                />
                <Button
                    label={t('Tips')}
                    value="tips"
                    className="btn btn-paydata"
                    onClick={submitButton}
                />
            </div>
            </div>
        </Fragment>
    )

}
export default PaydataSheet;