import React, { Component } from "react";

class CookiesDisabled extends Component {
    render() {
        return (
            <React.Fragment>
                <form>
                    <section>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 primary-bg width-50"></div>
                                <div className="col-6 width-50"></div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 primary-bg width-100"></div>
                                <div className="col-6 width-50"></div>
                            </div>
                        </div>
                    </section>
                    <section className="login-main border py-3 p-2 p-md-5">
                        <div className="login-in">
                            <div className="mb-3 d-flex justify-content-center">
                                {/* <img src="" /> */}
                            </div>
                            <div>
                                <h3 className="text-left"> We cann't sign you in </h3>
                                <p className="text-left">Your browser is currently set to block cookies. you need to allow to use this serice.Cookies are small text fies stored on your computer that tell us when you're signed in.
                                    To learn how to allow cookies, check the online help in your web browser.</p>
                            </div>
                        </div>
                    </section>
                </form>
                <section className="logincopy w-50 text-white p-2">@2023 copyright</section>
            </React.Fragment>
        )
    }
}
export default CookiesDisabled;