import React, { Fragment, useEffect, useState, useRef } from "react";
import * as userService from "../utils/userService";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../components/common/button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/common/loadingSpinner";
import ExceptionPopup from '../components/common/exceptionhandle';

function DoughCashTransactions() {
  const { t } = useTranslation();
  const didMount = useRef(false);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() - 30);
    return today;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [isLoad, setIsLoad] = useState(false);
  const [recentTransaction, setRecentTransaction] = useState([]);
  const [isException, setIsException] = useState(false);
  const [exceptionMsg, setExceptionMsg] = useState({ title: "warning", content: "This is a sample content" });

  useEffect(() => {
    if (!didMount.current) {
      getDoughTransactions();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getDoughTransactions = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        StartDate: moment(startDate).format("MM/DD/yyyy"),
        EndDate: moment(endDate).format("MM/DD/yyyy"),
      };

      httpService
        .Get(Employee_Api_EndPoints.GET_CARD_TRANSACTION, body)
        .then((resp) => {
          if (resp.data.transactionStatus.errorFound === true && resp.data.transactionStatus.errorCode === 'ERROR_RESPONSE') {
            // setExceptionMsg({ title: "Error", content: resp.data.errorMessage + <br/> + " Please contact with administrative team. Thank you!" });
            setExceptionMsg({ title: "Error", content: "Start Date must be less than End Date!" });
            setIsException(true);
          } else if (resp.data.transactionStatus.errorFound === true && resp.data.transactionStatus.errorCode === 'NO_CARD_DETAILS') {
            setExceptionMsg({ title: "Error", content: "Card account details not found" });
            setIsException(true);
          }

          setIsLoad(true);
          setRecentTransaction(resp.data.transactions);

        });
    } else {
      setIsLoad(true);
      userService.ClearSession();
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoad(false);
    setRecentTransaction([]);
    getDoughTransactions();
  };

  const handleClosePopup = () => {
    setIsException(false);
  };

  return (
    <Fragment>

      <div className="dashboard-container">
        <Link to="/doughcard" className="back-link">
          <i class="arrow left"></i> {t("backbutton")}
        </Link>
        <h2 className="text-center color-white">{t("RecentDoughCardTrs")}</h2>
        <div className="from-to-date-container mt-30 mb-20">
          <div className="date-picker-with-label">
            <label>{t("Start")}</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              timeZone={moment().format("Z")}
              className="datepicker"
              maxDate = {new Date()}
            />
          </div>
          <div className="date-picker-with-label">
            <label>{t("End")}</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
              dateFormat="MM/dd/yyyy"
              timeZone={moment().format("Z")}
              className="datepicker"
              maxDate = {new Date()}
            />
          </div>
          <div>
            <Button label={t("Gobtn")} className="btn" onClick={handleClick} Isdisabled={!isLoad} />
          </div>
        </div>

        <div class="table-container">
          {recentTransaction.length > 0 && (
            <>
              <table className="pay-info-table">
                <thead>
                  <tr>
                    <th>{t("TransactionID")}</th>
                    <th>{t("TransactionDateTime")}</th>
                    <th>{t("TransactionType")}</th>
                    <th>{t("Amnt")}</th>
                  </tr>
                </thead>
                <tbody>
                  {recentTransaction &&
                    recentTransaction.map((transfer, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{transfer.transactionID}</td>
                          <td className="text-center">
                            {transfer.transactionDateTime}
                          </td>
                          <td className="text-center">
                            {transfer.transactionType}
                          </td>
                          <td className="text-center">
                            $ {transfer.amount.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
          {recentTransaction.length === 0 && (
            <div className="nodata">{!isLoad ? <LoadingSpinner /> : t("nodatafoundmsg")}</div>
          )}
        </div>
      </div>
      {isException && <ExceptionPopup title={exceptionMsg.title} content={exceptionMsg.content} onClose={handleClosePopup} />}
    </Fragment>
  );
}
export default DoughCashTransactions;
