import React from "react";

const InpuField = ({
  name,
  value,
  label,
  type,
  placeholder,
  onChange,
  icon,
  iconclassName,
  ErrorBorder,
  ReadOnly,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <>
      {label && <label htmlFor="input-field">{label}</label>}
      <div className="input-wrap">
        {icon && (
          <span class="input-field-icon-wrap">
            <img src={icon} alt="" className={iconclassName} />
          </span>
        )}

        <input
          type={type}
          name={name}
          value={value}
          className="input-field"
          placeholder={placeholder}
          onChange={handleChange}
          error={ErrorBorder}
          readOnly={ReadOnly}
        />
      </div>
    </>
  );
};
export default InpuField;
