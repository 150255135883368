import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../components/common/dashboard";
import PrivateRoute from "./privateRoute";
import Login from "../views/login";
import Signup from "../views/signup";
// import Overview from "../views/overview";
import { BrowserRouter } from "react-router-dom";
import UpdateProfile from "../views/update-userprofile";
import DoughSummary from "../views/dough-summary";
import EmployeeTimesheet from "../views/timesheet";
import TIPSheet from "../views/tip";
import PaydataSheet from "../views/paydata";
import PayDataTimesheetDownload from "../views/paydata-timesheet";
import PayDataTipsheetDownload from "../views/paydata-tipsheet";
import MakingBreadCard from "../views/makingbread";
import DoughCard from "../views/dough-card";
import DoughCashTransactions from "../views/doughcash-transactions";
import DoughCashOtherTransactions from "../views/doughcash-othertransactions";
import DoughACHTransfers from "../views/dough-ach-transfers";
import InstaTransactions from "../views/transactions";
import AddBankAccounts from "../views/bankaccounts";
import Logout from "../views/logout";
import VirtualCardComponent from "../views/virtualcard";
import HelpPage from "../views/help";
import Company from "../views/company";
import Employers from "../views/employers";
import Employees from "../views/employees";
import FAQs from "../views/faqs";
import HomePage from "../views/home";
import DoughDepoTransactions from "../views/dough-depo-transactions";
// import OTP from "../views/otp";

class RouterConfig extends Component {
  componentDidMount() {
    window.addEventListener("popstate", this.onBackButtonEvent());
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackButtonEvent());
  }

  onBackButtonEvent = () => {
    window.history.forward();
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/home" element={<PrivateRoute component={<Dashboard />} />} />
          <Route path="/updateprofile" element={<PrivateRoute component={<UpdateProfile />} />} />
          <Route path="/doughsummary" element={<PrivateRoute component={<DoughSummary />} />} />
          <Route path="/timesheet" element={<PrivateRoute component={<EmployeeTimesheet />} />} />
          <Route path="/tipsheet" element={<PrivateRoute component={<TIPSheet />} />} />
          <Route path="/paysheet" element={<PrivateRoute component={<PaydataSheet />} />} />
          <Route path="/paytimesheet" element={<PrivateRoute component={<PayDataTimesheetDownload />} />} />
          <Route path="/paytipsheet" element={<PrivateRoute component={<PayDataTipsheetDownload />} />} />
          <Route path="/makingbreadcard" element={<PrivateRoute component={<MakingBreadCard />} />} />
          <Route path="/doughcard" element={<PrivateRoute component={<DoughCard />} />} />
          <Route path="/servicetransaction" element={<PrivateRoute component={<DoughCashTransactions />} />} />
          <Route path="/othertransaction" element={<PrivateRoute component={<DoughCashOtherTransactions />} />} />
          <Route path="/achtransaction" element={<PrivateRoute component={<DoughACHTransfers />} />} />
          <Route path="/transactions" element={<PrivateRoute component={<InstaTransactions />} />} />
          <Route path="/addaccount" element={<PrivateRoute component={<AddBankAccounts />} />} />
          <Route path="/virtualcard" element={<PrivateRoute component={<VirtualCardComponent />} />} />
          <Route path="/helppage" element={<PrivateRoute component={<HelpPage/>} />} />
          <Route path="/company" element={<Company />} />
          <Route path="/employers" element={<Employers />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/fAQs" element={<FAQs />} />
          <Route path="/homepage" element={<HomePage />} />
          <Route path="/depotransaction" element={<PrivateRoute component={<DoughDepoTransactions />} />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default RouterConfig;
