import React, { useState, useEffect, useRef, Fragment } from "react";
// import cardProviderLogo from "../../assets/images/card-provider-logo.svg";
import * as httpService from "../../services/httpService";
import { Employee_Api_EndPoints } from "../../global/end-points";
import * as userService from "../../utils/userService";
import { Link } from "react-router-dom";
import LoadingSpinner from "../common/loadingSpinner";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(false);
  const didMount = useRef(false);
  const [breadPoints, setBreadPoints] = useState({
    rafilTarget: 0,
    breadPointsPerTrans: 0,
    earnedPoints: 0,
    totalPay: 0,
  });
  // const { rafilTarget, earnedPoints, breadPointsPerTrans, totalPay } = breadPoints;
  const { totalPay } = breadPoints;

  useEffect(() => {
    if (!didMount.current) {
      get_target_earned_points();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const get_target_earned_points = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        LocationId: 1,
      };

      httpService
        .Get(Employee_Api_EndPoints.EARNED_POINTS, body)
        .then((resp) => {
          if (resp.status == 200) {
            // breadPoints.BreadPointsPerTrans = resp.data.breadPointsPerTrans;
            // breadPoints.EarnedPoints = resp.data.earnedPoints;
            setBreadPoints({ ...resp.data });
            // barInfo.data = bindData(
            //   resp.data.rafilTarget,
            //   resp.data.earnedPoints
            // );
            // barInfo.optionInfo = bindOptions();
            // setBarInfo({ ...barInfo });
          }
          setIsLoad(true);
        });
    } else {
      setIsLoad(false);
      userService.ClearSession();
    }
  };

  return (
    <Fragment>
      {isLoad && (
        <div className="dashboard-container">
          {/* summary cards row start */}
          <div className="dashboard-card-row">
            <div className="summary-card">
              {/* card inner start */}
              <div className="summary-card-inner">
                {/* card details start */}
                <div className="summary-card-details">
                  <h2 className="card-heading">{t("summary")}</h2>
                  <h2 className="wages-heading">
                    {t("availableWagestoAccess")}
                  </h2>
                  {totalPay.toFixed(2) == 0.00 ? (
                    <div>
                      <Link className="wage-amount">
                      $ {totalPay.toFixed(2)}
                      </Link>
                      <h3 className="wage-card-number">{t("NoEarnedWages")}</h3>
                    </div>
                  ) : (
                    <Link to="/doughsummary" className="wage-amount">
                    $ {totalPay.toFixed(2)}
                    </Link>
                  )}
                  {/* <Link to="/doughsummary" className="wage-amount">
                    $ {totalPay.toFixed(2)}
                  </Link> */}
                  {/* <h3 className="wage-card-number">**** **** **** 1234</h3> */}
                  <div className="card-holder-details-provider">
                    {/* <div className="card-holder-details">
                      <div className="valid">
                        Valid Thru
                        <span>08/28</span>
                      </div>
                      <div className="valid">
                        Card holder
                        <span>{sessionStorage?.UserName}</span>
                      </div>
                    </div> */}
                    {/* <div className="card-provider-symbol">
                      <img src={cardProviderLogo} alt="" />
                    </div> */}
                  </div>
                </div>
                {/* card details end */}
              </div>
              {/* card inner end */}
            </div>
            <div className="get-your-dough-card">
              <div className="dough-card-inner">
                {/* <button className="get-your-dough-btn"> */}
                {/* Get Your Dough Now! */}
                <Link to="/doughsummary" className="get-your-dough-btn">
                  {t("GetDh")}
                </Link>
                {/* </button> */}
                <label className="get-your-dough-label">
                  {/* Earn {breadPointsPerTrans} bread points */}
                </label>
              </div>
            </div>
          </div>
          {/* summary cards row end */}
          {/* Rewards start */}
          {/* <div className="rewards-main">
            <h2 className="dashboard-section-heading">{t("Rewards")}</h2>
            <div className="rewards-row"> */}
              {/* reward */}
              {/* <div className="reward-wrap">
                <div className="reward-icon">$</div>
                <div className="reward-label">{t("LoremIpsum")}</div>
              </div> */}
              {/* reward */}
              {/* <div className="reward-wrap">
                <div className="reward-icon">$</div>
                <div className="reward-label">{t("LoremIpsum")}</div>
              </div> */}
              {/* reward */}
              {/* <div className="reward-wrap">
                <div className="reward-icon">$</div>
                <div className="reward-label">{t("LoremIpsum")}</div>
              </div>
            </div>
          </div> */}
          {/* Rewards end */}
          {/* <!-- Bread Points Graph start --> */}
          <div class="bread-points-section">
            {/* <h2 class="dashboard-section-heading">{t("BrdPnts")}</h2> */}

            {/* <div class="bread-points-graph">
              <div class="target-points-wrapper">
                <label class="target-points-label">{t("TrgtRaffle")}</label>
              </div>
              <div class="earned-points-wrapper">
                <label class="earned-points-label">{t("BrdPntsEarned")}</label>
              </div>
            </div> */}
            <div class="bread-points-graph bar-graph-container">
              {/* <div class="target-points-wrapper"> */}
                {/* <div class="target-points-graph">{rafilTarget}</div> */}
              {/* </div> */}

              <div class="earned-points-wrapper">
                {/* <div class="earned-points-graph" style={{ width: earnedPoints / 100 + '%' }}>{earnedPoints}</div> */}
                {/* <div class="earned-points-graph" style={{ width: "40%" }}>
                  400
                </div> */}
              </div>
            </div>
          </div>
          {/* <!-- Bread Points Graph end --> */}
        </div>
      )}
      {!isLoad && <LoadingSpinner />}
    </Fragment>
  );
}
export default Dashboard;
