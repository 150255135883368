import axios from "axios";
import * as userService from '../utils/userService';
// import LoadingSpinner from '../components/common/loadingSpinner';

export const Get = (url, params) => {
    return axios({
        method: 'get',
        url: url,
        params: params,
        headers: {
            'Content-Type': 'application/json',
            'AccessToken': userService.GetUserToken(),
            'pragma': 'no-cache'
        }
    }).then((res) => {
        return res;
    }, (err) => {
        if (err.response.status === 401 && err.response.data?.message === "Unauthorized") {
            userService.ClearCookies();
        } else {
            return err;
        }
    });
}

export const Post = (_url, param) => {
    return axios({
        method: 'post',
        url: _url,
        data: param,
        headers: {
            'Content-Type': 'application/json',
            'AccessToken': userService.GetUserToken(),
            'pragma': 'no-cache'
        }
    }).then((res) => {
        return res;
    }, (err) => {
        if (err.response.status === 401 && err.response.data?.message === "Unauthorized") {
            userService.ClearCookies();
        } else {
            return err;
        }
    })
}

// export const LoadingWheel = (flag) => {
//     return (
//         flag && <LoadingSpinner />
//     )
// }

// export const Put = (url, data) => {
//     try {
//         return axios.put(url, data, { headers: SetHeaders() })
//             .then((resp) => { return resp; })
//             .catch((err) => { return err; })
//     } catch (error) {
//         console.log(error);
//     }
// }

// export const Delete = (url) => {
//     try {
//         return axios.delete(url, { headers: SetHeaders() })
//             .then((resp) => { return resp; })
//             .catch((err) => { return err; })
//     } catch (error) {
//         console.log(error);
//     }
// }