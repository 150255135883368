import React from "react";
import Header from "../components/common/header";
import MenuComponent from "../components/common/menu";

const PrivateRoute = ({ component: Component }) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="dashboard">
          <MenuComponent />
          <div class="dashboard-right">
            <Header />
            {Component}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivateRoute;
