import React, { useState, useEffect, useRef, Fragment } from "react";
import * as httpService from "../services/httpService";
import * as userService from "../utils/userService";
import { Employee_Api_EndPoints } from "../global/end-points";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import * as wageService from '../components/common/wage_calculations';
import LoadingSpinner from "../components/common/loadingSpinner";
import { useTranslation } from "react-i18next";
import ExceptionPopup from '../components/common/exceptionhandle';

function DoughSummary() {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);
  const [cashSummary, setCashSummary] = useState({
    wages: 0,
    tip: 0,
    grossPay: 0,
    netPay: 0,
  });
  // const [taxDeduction, setTaxDeduction] = useState({ totalTax: 0 });
  // const { totalTax } = taxDeduction;
  const { wages, tip, netPay } = cashSummary;
  const didMount = useRef(false);
  let navigate = useNavigate();
  const [isException, setIsException] = useState(false);
  const [exceptionMsg, setExceptionMsg] = useState({ title: "warning", content: "This is a sample content" });

  useEffect(() => {
    if (!didMount.current) {
      getCashSummaryInfo();
    }
    return () => {
      didMount.current = true;
    };
  }, []);

  const getCashSummaryInfo = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        LocationId: 1,
      };

      httpService
        .Get(Employee_Api_EndPoints.DOUGH_SUMMARY, body)
        .then((resp) => {
          if (resp.status == 200) {
            setCashSummary({ ...resp.data });
            // setTaxDeduction({ ...wageService.CalTaxDedution(resp.data?.calTaxDeductions) });
          }
          setIsLoad(true);
        });
    } else {
      setIsLoad(true);
      userService.ClearSession();
    }
  };

  const onRangeChange = (event) => {
    event.preventDefault();
    const enterValue = event.target.value.replace(/[^0-9]/g, "");
    if (enterValue <= netPay) {
      setRangeValue(Number(enterValue));
    }
  };

  const makeBreadCard = () => {
    if (sessionStorage.UserId != undefined) {
      const body = {
        UserId: Number(sessionStorage.UserId),
        Amount: rangeValue,
        LocationId: 1,
      };
      setIsLoad(false);
      httpService.Get(Employee_Api_EndPoints.TRANSFER_BALANCE, body).then(
        (resp) => {
          if (resp.data.errorFound === true && resp.data.errorCode === 'EXCEPTION_ERROR') {
            setExceptionMsg({ title: "Error", content: resp.data.errorMessage + <br/> + " Please contact with administrative team. Thank you!" });
            setIsException(true);
          } else if (resp.data.errorFound === true && resp.data.errorCode === 'NO_CARD_DETAILS') {
            setExceptionMsg({ title: "Warning", content: "Card account details not found" });
            setIsException(true);
          } else if (resp.data.errorFound === true && resp.data.errorCode === 'LOADFUNDS_FAILED') {
            setExceptionMsg({ title: "Warning", content: resp.data.errorMessage });
            setIsException(true);
          } else {
            if ((resp.data.errorFound === false && resp.status == 200)) {
              navigate("/makingbreadcard", {
                state: { 
                  bakingValues: resp.data,
                  typeOfTransfer: 'loadFundsToCard'
                }
              });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      userService.ClearSession();
    }
  };

  const handleClosePopup = () => {
    setIsException(false);
  };

  return (
    <Fragment>
      {isLoad && (
        <Fragment>
          <div className="dashboard-container">
            <Link to="/home" className="back-link">
              <i class="arrow left"></i> {t("backbutton")}
            </Link>

            <h2 class="page-module-heading mb-15">{t("DhCashSumm")}</h2>
            {/* <h2 class="page-module-heading">Total Balance</h2>
            <h1 class="available-dough-bigger color-white">$ {grossPay}</h1> */}
            <h2 class="page-module-heading">{t('AvaiTranBaAfterDed')}</h2>
            <h1 class="available-dough-bigger available-wage-tip">$ {netPay.toFixed(2)}</h1>
            <div class="dough-card-draw-option">
              <p class="draw-question-label">{t("HowMuchOnDhCrd")}</p>
              <div class="wage-display-container">
                <Link to="/timesheet" class="wage-tip-container">
                  <span>{t('WageAvai')}</span>
                  <h3 class="available-wage-tip">$ {wages.toFixed(2)}</h3>
                </Link>
                <Link to="/tipsheet" class="wage-tip-container">
                  <span>{t('TipAvai')}</span>
                  <h3 class="available-wage-tip">$ {tip.toFixed(2)}</h3>
                </Link>
              </div>
              <div class="slider-container">
                <input
                  type="range"
                  min="0"
                  max={netPay}
                  value={rangeValue}
                  class="slider"
                  id="myRange"
                  onChange={onRangeChange}
                />
              </div>
              <div class="wage-input-container">
                <span>{t("EntrAmnt")}</span>
                <input
                  type="text"
                  class="input-wage"
                  value={rangeValue.toLocaleString()}
                  onChange={onRangeChange}
                />
                <button
                  class="btn"
                  disabled={rangeValue > 0 ? false : true}
                  onClick={makeBreadCard}
                >
                  {t("ConfBake")}
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {!isLoad && <LoadingSpinner />}
      {isException && <ExceptionPopup title={exceptionMsg.title} content={exceptionMsg.content} onClose={handleClosePopup} />}
    </Fragment>
  );
}
export default DoughSummary;
