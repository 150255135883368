import React, { useState, Fragment } from "react";
import InpuField from "../components/common/input";
import Button from "../components/common/button";
import logo from "../assets/images/logo.svg";
import LoginImage from "../assets/images/login_01.svg";
import User from "../assets/images/user.svg";
import Lock from "../assets/images/lock.svg";
import Name from "../assets/images/name.svg";
import Email from "../assets/images/email.svg";
import Mobile from "../assets/images/mobile.svg";
import viewPassword from "../assets/images/show.png";
import hidePassword from "../assets/images/hide.png";
import validator from "validator";
import ErrorMessage from "../components/common/error_message";
import * as httpService from "../services/httpService";
import { Employee_Api_EndPoints } from "../global/end-points";
import OTP from "../views/otp";
import "react-phone-number-input/style.css";

function Signup() {
  const [inputValue, setInputValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    cpassword: "",
    mobile: "",
    employeeid: "",
  });
  const {
    firstname,
    lastname,
    email,
    password,
    cpassword,
    mobile,
    employeeid,
  } = inputValue;
  const [errorMessage, setErrorMessage] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    cpassword: "",
    mobile: "",
    employeeid: "",
    allEmpty: "",
  });
  const [switchOtp, setSwitchOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleChange = (key, value) => {
    setInputValue((prev) => ({
      ...prev,
      [key]: value,
    }));

    setErrorMessage((err) => ({
      ...err,
      [key]: "",
    }));
  };

  const checkValidation = () => {
    let errors = errorMessage;
    if (!inputValue.firstname.trim()) {
      errors.firstname = "*";
    } else {
      if (inputValue.firstname.length < 2) {
        errors.firstname = "Invalid entry";
      } else {
        errors.firstname = "";
      }
    }

    if (!inputValue.lastname.trim()) {
      errors.lastname = "*";
    } else {
      if (inputValue.lastname.length < 2) {
        errors.lastname = "Invalid entry";
      } else {
        errors.lastname = "";
      }
    }

    if (!inputValue.email.trim()) {
      errors.email = "*";
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputValue.email)) {
        errors.email = "Enter valid Email Id";
      } else {
        errors.email = "";
      }
    }

    if (!inputValue.password.trim()) {
      errors.password = "*";
    } else {
      if (
        !validator.isStrongPassword(inputValue.password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        errors.password = "Invalid formate";
      } else {
        errors.password = "";
      }
    }

    if (!inputValue.cpassword.trim()) {
      errors.cpassword = "*";
    } else {
      if (
        !validator.isStrongPassword(inputValue.cpassword, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        errors.cpassword = "Password not a match";
      } else {
        const { password, cpassword } = inputValue;
        if (password.trim() === "") {
          errors.password = "Password is required!";
        } else {
          if (password.trim() !== cpassword.trim()) {
            errors.cpassword = "Password not a match";
          } else {
            errors.cpassword = "";
          }
        }
      }
    }

    if (!inputValue.mobile.trim()) {
      errors.mobile = "*";
    } else {
      const phoneRegex = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
      if (!phoneRegex.test(inputValue.mobile)) {
        errors.mobile = "Enter a valid mobie number";
      }
    }

    if (!inputValue.employeeid.trim()) {
      errors.employeeid = "*";
    } else {
      errors.employeeid = "";
    }

    errors.allEmpty = mandatory_validation(errors);
    setErrorMessage({ ...errorMessage, [errorMessage]: errors });
    for (let i = 0; i < Object.keys(errors).length; i++) {
      if (Object.values(errors)[i].length > 0) {
        return false;
      }
    }

    return true;
  };

  const mandatory_validation = (error_validation) => {
    let msg = "";
    for (let x = 0; x < Object.keys(error_validation).length; x++) {
      if (Object.values(error_validation)[x] == "*" || msg.length) {
        return (msg = "All fields marked (*) is mandatory");
      } else {
        msg = "";
      }
    }
    return msg;
  };

  const submitButton = () => {
    if (checkValidation()) {
      setDisabled(true);
      const userInfo = {
        FirstName: inputValue.firstname,
        LastName: inputValue.lastname,
        Password: inputValue.password,
        CPassword: inputValue.cpassword,
        Email: inputValue.email,
        PhoneNumber: inputValue.mobile,
        EmployeeId: inputValue.employeeid,
        IsValidEmail: inputValue.email,
      };
      httpService.Get(Employee_Api_EndPoints.VALIDATE_USER_INFO, userInfo).then(
        (resp) => {
          if (resp.code === "ERR_NETWORK" || resp.code === "ERR_BAD_REQUEST") {
            if (resp.response.status === 400) {
              server_validations(resp.response.data.errors);
            }
            setDisabled(false);
            return false;
          }
          if (resp.status === 200) {
            if (resp.data.isValid === false && resp.data.message === "NOT_VALID_EMP_ID") {
              errorMessage.employeeid =
                "EmployeeId is not matched with your email.";
              setErrorMessage({ ...errorMessage });
            } else if (resp.data.isValid === false && resp.data.message === "NOT_VALID_PHONE_NUMBER") {
              errorMessage.mobile = "phoneNumber is not matched with email.";
              setErrorMessage({ ...errorMessage });
            } else {
              setSwitchOtp(true);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const server_validations = (error_key) => {
    let server_errors = errorMessage;
    Object.keys(error_key).forEach((err_msg) => {
      switch (err_msg) {
        case "FirstName":
          server_errors.firstname = error_key[err_msg].toString();
          break;
        case "LastName":
          server_errors.lastname = error_key[err_msg].toString();
          break;
        case "Email":
          server_errors.email = error_key[err_msg].toString();
          break;
        case "Password":
          server_errors.password = error_key[err_msg].toString();
          break;
        case "CPassword":
          server_errors.cpassword = error_key[err_msg].toString();
          break;
        case "PhoneNumber":
          server_errors.mobile = error_key[err_msg].toString();
          break;
        case "EmployeeId":
          server_errors.employeeid = error_key[err_msg].toString();
          break;
        case "IsValidEmail":
          server_errors.email = error_key[err_msg].toString();
          break;
      }
    });
    setErrorMessage({ ...errorMessage, [errorMessage]: server_errors });
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      submitButton();
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters from the input
    const numericInput = input.replace(/\D/g, '');

    // Apply the formatting (e.g., (123) 456-7890)
    let formattedNumber = '';
    for (let i = 0; i < numericInput.length; i++) {
      if (i === 0) {
        formattedNumber += '(';
      } else if (i === 3) {
        formattedNumber += ') ';
      } else if (i === 6) {
        formattedNumber += '-';
      }
      formattedNumber += numericInput[i];
    }
    return formattedNumber;
  };

  const handleInputMobileNumber = (e) => {
    const formattedValue = formatPhoneNumber(e);
    handleChange('mobile', formattedValue);
  }

  return (
    <Fragment>
      <div className="container">
        <div className="login-register">
          <div className="login-image">
            <img src={LoginImage} alt="" />
          </div>

          {/* singup form start  */}
          {!switchOtp && (
            <div className="login-form">
              <div className="logo">
                <img src={logo} alt="InstaDouch Logo" />
              </div>

              <h2 className="login-register-form-heading mt-40 mb-20">
                <span>Create your Account</span>
              </h2>
              <div onKeyDown={handleEnter}>
                <div className="form-row">
                  {errorMessage.firstname && (
                    <ErrorMessage message={errorMessage.firstname} />
                  )}
                  <InpuField
                    className="input-field"
                    type="text"
                    name="firstname"
                    value={firstname}
                    placeholder="First Name"
                    icon={Name}
                    iconclassName={`user-icon`}
                    onChange={handleChange}
                    ErrorBorder="true"
                  />
                </div>

                <div className="form-row">
                  {errorMessage.lastname === "" ? null : (
                    <ErrorMessage message={errorMessage.lastname} />
                  )}

                  <InpuField
                    className="input-field"
                    type="text"
                    name="lastname"
                    value={lastname}
                    placeholder="Last Name"
                    icon={Name}
                    iconclassName={`lock-icon`}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  {errorMessage.email === "" ? null : (
                    <ErrorMessage message={errorMessage.email} />
                  )}
                  <InpuField
                    className="input-field"
                    type="text"
                    name="email"
                    value={email}
                    placeholder="Email Id"
                    icon={Email}
                    iconclassName={`user-icon`}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  {errorMessage.password === "" ? null : (
                    <ErrorMessage message={errorMessage.password} />
                  )}
                  <InpuField
                    className="input-field"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    placeholder="Create Password"
                    icon={Lock}
                    iconclassName={`lock-icon`}
                    onChange={handleChange}
                  />
                  <button
                    onClick={handleTogglePassword}
                    className="showHidePassword"
                  >
                    {showPassword ? (
                      <img
                        className="logo"
                        src={viewPassword}
                        alt="InstaDouch Logo"
                      />
                    ) : (
                      <img
                        className="logo"
                        src={hidePassword}
                        alt="InstaDouch Logo"
                      />
                    )}
                  </button>
                </div>

                <div className="form-row">
                  {errorMessage.cpassword === "" ? null : (
                    <ErrorMessage message={errorMessage.cpassword} />
                  )}
                  <InpuField
                    className="input-field"
                    type={showConfirmPassword ? "text" : "password"}
                    name="cpassword"
                    value={cpassword}
                    placeholder="Confirm Password"
                    icon={Lock}
                    iconclassName={`lock-icon`}
                    onChange={handleChange}
                  />
                  <button
                    onClick={handleToggleConfirmPassword}
                    className="showHidePassword"
                  >
                    {showConfirmPassword ? (
                      <img
                        className="logo"
                        src={viewPassword}
                        alt="InstaDouch Logo"
                      />
                    ) : (
                      <img
                        className="logo"
                        src={hidePassword}
                        alt="InstaDouch Logo"
                      />
                    )}
                  </button>
                </div>

                <div className="form-row">
                  {errorMessage.mobile === "" ? null : (
                    <ErrorMessage message={errorMessage.mobile} />
                  )}
                  <div className="input-wrap">
                    <span class="input-field-icon-wrap">
                      <img src={Mobile} alt="" className="mobile-icon" />
                    </span>
                    <input
                      type="text"
                      icon={Mobile}
                      name="mobile"
                      value={mobile}
                      // onChange={(e) => handleChange("mobile", e.target.value)}
                      onChange={(e) => handleInputMobileNumber(e.target.value)}
                      maxLength={15}
                      className="input-field"
                      placeholder="(123) 456-7890"
                    />
                  </div>
                </div>

                <div className="form-row">
                  {errorMessage.employeeid === "" ? null : (
                    <ErrorMessage message={errorMessage.employeeid} />
                  )}
                  <InpuField
                    className="input-field"
                    type="text"
                    name="employeeid"
                    value={employeeid}
                    placeholder="Employee ID"
                    icon={User}
                    iconclassName={`user-icon`}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  {errorMessage.allEmpty === "" ? null : (
                    <ErrorMessage
                      className="validation-message-summary"
                      message={errorMessage.allEmpty}
                    />
                  )}
                </div>
              </div>
              <div className="form-row new-user-link-wrap">
                <Button
                  label="Sign Up"
                  className="btn btn-login"
                  Isdisabled={disabled}
                  onClick={submitButton}
                />
              </div>
            </div>
          )}
          {/* singup screen end  */}

          {/* started Otp Page */}
          {switchOtp && <OTP signUpInfo={inputValue} />}
          {/* otp page end */}
        </div>
      </div>
    </Fragment>
  );
}
export default Signup;
