import React, { Fragment,useEffect,useState } from "react";
import Cookies from "universal-cookie";
import logo from "../assets/images/logo.svg";

function Employees() {



    const [isload, setLoad] = useState(false);
  useEffect(() => {
    // if (!didMount.current) {
    const cookies = new Cookies();
    const _loginForm = cookies.get("loginForm");
    if (_loginForm !== undefined) {
      // Object.keys(_loginForm).forEach(cookieName => {
      //   inputValue[cookieName] = _loginForm[cookieName];
      // });

      
    }
    // setInputValue({ ...inputValue });
    setLoad(true);
    // }
    // return () => {
    //   didMount.current = true;
    // };
  }, []);

       

  return (
    <Fragment>
      {" "}
      {isload && (
        <>
          <div className="login-page-header">
            <a href="/" className="logo-container">
              <img src={logo} alt="InstaDouch Logo" className="logo" />
            </a>
            <nav>
            <a href="/homepage" className="nav-menu-item">
                Home
              </a>
              <a href="/company" className="nav-menu-item">
                Company
              </a>
              <a href="/employers" className="nav-menu-item">
                Employers
              </a>
              <a href="/employees" className="nav-menu-item login-link">
                Employees
              </a>
              <a href="/fAQs" className="nav-menu-item">
                FAQs
              </a>
              <a href="/" className="nav-menu-item">
                Login
                <div className="login-dropdown-container">
                  <a href="#" className="login-dropdown-link-item">
                    Employee Login
                  </a>
                  <a href="#" className="login-dropdown-link-item">
                    Employer Login
                  </a>
                </div>
              </a>
            </nav>
          </div>
            {/* Login screen end  */}
            <div className="employers-container">
            <div className="employers" id="access">
              <h3>Employees benefit immensely by having the flexibility of instant pay access. A few of these benefits are detailed below:</h3>
            </div>
            <div className="employers" id="Less">
              <h3>Less Stress</h3>
            </div>
            <div className="employers" id="Solution">
              <h3>Solution for the Unbankable </h3>
            </div>
            <div className="employers" id="Costs">
              <h3>Less Costs</h3>
            </div>
          </div>
          <div className="contact-us-widget">
              <h3>Refer your employer!</h3>
            </div>
        </>
      )}
    </Fragment>
  );
}

export default Employees;
