import React from "react";

const buttonStyle = {
  margin: "10px 0",
  cursor: "pointer",
};

const Button = ({ label, value, onClick, Isdisabled = false }) => {
  return (
    <button
      type="button"
      className="btn btn-default w-100-percent"
      value={value}
      style={buttonStyle}
      onClick={onClick}
      disabled={Isdisabled}
    >
      {label}
    </button>
  );
};
export default Button;
