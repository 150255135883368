import React, { Fragment } from 'react';

const ExceptionPopup = ({ title, content, onClose }) => {
    return (
        <Fragment>
            <div className="popup-backdrop"></div>
            <div className="popup">
                <div className="popup-header">
                    <h2 className="popup-heading">{title}</h2>
                    <button className="close" onClick={onClose}></button>
                </div>
                <div className="popup-body">
                    {content}
                </div>
            </div>
        </Fragment>
    )
}
export default ExceptionPopup;